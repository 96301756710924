import React, { useCallback, useContext, useMemo, useState } from "react"
import axiosInstance from "../../api/axiosInstance"
import linkIcon from "../../assets/icons/link.svg"
import { ClientHouseholdCacheContext } from "../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../contexts/FirmContext"
import { useTheme } from "../../contexts/ThemeContext"
import useTrackViewEvent from "../../hooks/useTrackViewEvent"
import { efpUrl, latestGamesByType } from "../../lib/clients"
import { Client, GameType } from "../../models/Client"
import AlertPopover, { AlertPopoverOptions } from "../AlertPopover/AlertPopover"
import ClientGameStatusCard from "./ClientGameStatusCard/ClientGameStatusCard"
import ClientStatusAlert, { StatusAlert } from "./ClientStatusRow/ClientStatusAlert"
import Carousel from "../Carousel/Carousel"
import { legacyCopyToClipboard } from "../../lib/utils"
import { useSearchParams } from "react-router-dom"

const ClientEFPStatusRow = ({ client, availableGames }: { client: Client; availableGames: GameType[] }) => {
  const { replace } = useContext(ClientHouseholdCacheContext)
  const { firm } = useContext(FirmContext)
  const theme = useTheme()
  const trackViewEvent = useTrackViewEvent()
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const [queryParams] = useSearchParams()
  const isEmbedded = !!queryParams.get("facet")

  const createEFP = useCallback(() => {
    return axiosInstance.post(`${import.meta.env.VITE_APP_API_BASE || ""}/api/client/${client._id.toString()}/efp`, {}).then((res) => res.data as Client)
  }, [client._id])

  const onCopyLink = useCallback(() => {
    trackViewEvent({ action: "click", category: "client_invitation", label: "copy_invite" })
    setInfoAlert({
      message: "Copying...",
      timeout: false,
      type: "activity",
      location: "copy_link"
    })

    const preCopy = () =>
      createEFP().then((updated: Client) => {
        replace(updated)
        return efpUrl(updated)
      })

    const whenCopied = () =>
      setInfoAlert({
        message: "Link copied to clipboard",
        onClose: () => setInfoAlert(undefined),
        type: "success",
        location: "copy_link"
      })

    copyToClipboard(isEmbedded, preCopy).then(whenCopied)
  }, [createEFP, isEmbedded, replace, trackViewEvent])

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (!infoAlert) {
      return
    }
    return {
      content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
      onClose: infoAlert.onClose,
      timeoutMS: infoAlert.timeout === false ? null : 5000,
      location: infoAlert.location
    }
  }, [infoAlert])

  const gameByType = latestGamesByType({ client, firm, theme })

  const order = theme.games.map((game) => game.type)
  const sortedAvailableGames = useMemo(() => [...availableGames].sort((a, b) => order.indexOf(a) - order.indexOf(b)), [availableGames, order])
  const gamesSortedByEnabled = useMemo(() => {
    const firmGames = firm?.config?.supportedGames?.map((g) => g.type) ?? ["risk"]
    return [...firmGames].sort((a, b) => {
      const indexA = sortedAvailableGames.indexOf(a)
      const indexB = sortedAvailableGames.indexOf(b)
      return Number(indexA === -1) - Number(indexB === -1) || indexA - indexB
    })
  }, [firm?.config?.supportedGames, sortedAvailableGames])

  return (
    <div className="client-efp-section flex flex-col gap-y-6 pl-19">
      <div className="flex gap-x-1 items-center">
        <h2 className="text-h3 font-semibold">{client.firstName}'s Economic Fingerprint</h2>
        <AlertPopover options={alertPopoverOptions} show={!!infoAlert && infoAlert.location === "copy_link"}>
          <button className="copy-link-btn btn btn-text btn-text-md flex items-center justify-center align-middle gap-2" onClick={onCopyLink}>
            <img className="copy-link-btn__img" src={linkIcon} alt="" aria-hidden />
            Copy link
          </button>
        </AlertPopover>
      </div>
      {theme.pages.profile.sections.clientStatus.show && (
        <div className="flex flex-row gap-x-3 w-full">
          <Carousel>
            {gamesSortedByEnabled.map((game) => (
              <ClientGameStatusCard key={game} client={client} game={gameByType[game]} gameType={game} enabled={availableGames.includes(game)} />
            ))}
          </Carousel>
        </div>
      )}
    </div>
  )
}

export default ClientEFPStatusRow

export function copyToClipboard(isEmbedded: boolean, preCopy: () => Promise<string>) {
  if (isEmbedded) {
    return preCopy().then((url) => legacyCopyToClipboard(url))
  } else if (typeof ClipboardItem && navigator.clipboard.write) {
    return navigator.clipboard.write([
      new ClipboardItem({
        "text/plain": preCopy().then((url) => new Blob([url], { type: "text/plain" }))
      })
    ])
  } else {
    return preCopy().then((url) => navigator.clipboard.writeText(url))
  }
}
