import { t } from "@lingui/macro"
import { useTheme } from "../../../contexts/ThemeContext"
import Page from "./Page"
import { PrintCauseDetail } from "./PrintCauseDetail"

const AppendixPage2 = ({ page }: { page: number }) => {
  const theme = useTheme()
  return (
    <>
      {Array.from({ length: Math.ceil(Object.values(theme.causes!.S1!).slice(4).length / 3) }).map((_, index) => (
        <Page key={index} page={page} title={t({ id: "print-report-page-header-title" })}>
          <div className="mx-9">
            {Object.values(theme.causes!.S1!)
              .slice(4) // Start from the 5th element
              .slice(index * 3, (index + 1) * 3) // Get 3 items per page
              .map((cause) => (
                <PrintCauseDetail cause={cause} key={cause.id} />
              ))}
          </div>
        </Page>
      ))}
    </>
  )
}

export default AppendixPage2
