import { formatISO } from "date-fns"
import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { updateHousehold } from "../../../../../../api/households"
import AlertPopover, { AlertPopoverOptions } from "../../../../../../components/AlertPopover/AlertPopover"
import ClientStatusAlert, { StatusAlert } from "../../../../../../components/ClientProfile/ClientStatusRow/ClientStatusAlert"
import Loading from "../../../../../../components/ClientProfile/Loading/Loading"
import DatePicker from "../../../../../../components/DatePicker/DatePicker"
import { AppContext } from "../../../../../../contexts/AppContext"
import { ClientHouseholdCacheContext } from "../../../../../../contexts/ClientHouseholdCacheContext"
import { useTheme } from "../../../../../../contexts/ThemeContext"
import { TourControllerContext } from "../../../../../../contexts/TourController"
import useTrackViewEvent from "../../../../../../hooks/useTrackViewEvent"
import { customDateFormat, formatDateLong } from "../../../../../../lib/date"
import { Household } from "../../../../../../models/Household"
import { AuthContext } from "../../../../../../views/auth/AuthContext"
import Popover from "../../../../../advisor/components/Popover/Popover"
import ReprofileConfirmationModal from "../../../../../advisor/components/ReprofileConfirmationModal/ReprofileConfirmationModal"
import UnArchiveConfirmationModal from "../../../../../advisor/components/UnArchiveConfirmationModal/UnArchiveConfirmationModal"
import ResultsAvailableMenu from "../../../../../clients/components/Profile/components/ResultsAvailableMenu/ResultsAvailableMenu"
import { Game } from "../../../../../../models/Client"
import ProviderAlert from "../../../../../clients/components/ProfileOverview/components/EditProfileModal/ProviderAlert"

const HouseholdGameStatusRow = ({ household, game }: { household: Household, game: Game }) => {
  const { archivedAt, nextActivityDue, status } = household
  const tourController = useContext(TourControllerContext)
  const theme = useTheme()
  const { tourSteps, progress, step, completeStep, isLastStep } = tourController
  const nextActivityDueDate = useMemo(() => (nextActivityDue ? new Date(nextActivityDue) : undefined), [nextActivityDue])
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)
  const [infoAlert, setInfoAlert] = useState<StatusAlert | undefined>(undefined)
  const { replace } = useContext(ClientHouseholdCacheContext)
  const { sessionInfo } = useContext(AuthContext)
  const { userProfile } = useContext(AppContext)
  const [showUnArchiveModal, setShowUnArchiveModal] = useState(false)
  const [showReprofileModal, setShowReprofileModal] = useState(false)
  const trackViewEvent = useTrackViewEvent()

  const onEditNextActivityDueDate = useCallback(
    (nextActivityDue: string) => {
      setIsTransitioning(true)
      updateHousehold(sessionInfo!, household, [], { nextActivityDue })
        .then((updatedHousehold) => {
          replace(updatedHousehold)
          setIsTransitioning(false)
          setInfoAlert({
            location: "nextActivityDue",
            message: "Date has been successfully updated",
            onClose: () => setInfoAlert(undefined),
            type: "success"
          })
        })
        .catch(() => {
          // setErrorMessage(error.message) TODO
          setIsTransitioning(false)
        })
    },
    [household, sessionInfo, replace]
  )

  const alertPopoverOptions: AlertPopoverOptions | undefined = useMemo(() => {
    if (!infoAlert) {
      return
    }
    return {
      content: <ClientStatusAlert type={infoAlert.type} message={infoAlert.message} />,
      onClose: infoAlert.onClose,
      timeoutMS: infoAlert.timeout === false ? null : 5000,
      location: infoAlert.location
    }
  }, [infoAlert])

  return (
    <>
      <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between gap-2 py-3">
        <div className="flex gap-3 items-center">
          <div className="text-p text-main-500 font-normal text-left">
            <div className="flex gap-2 items-center">
              {household.status !== "Archived" && game && game[game.gameType!]?.results && !game.summaryDate && (
                <div className="flex gap-x-1 items-center">
                  <p>The household results are ready to present</p>
                  <ResultsAvailableMenu household={household} game={game} />
                </div>
              )}
              {household.status !== "Archived" && game && game[game.gameType!]?.results && game.summaryDate && (
                <div className="flex flex-col">
                  <span>Household is up to date</span>
                  {nextActivityDue && nextActivityDueDate && (
                    <div className="flex text-sm">
                      <span>Re-profile due on</span>
                      <DatePicker
                        id="nextActivityDue"
                        yearsInPast={0}
                        isInline={true}
                        customInput={<span className="text-sm text-link-600 hover:text-link-500 underline ml-1">{formatDateLong(nextActivityDue)}</span>}
                        onChange={(value: Date | null) => {
                          onEditNextActivityDueDate(formatISO(value!.setHours(0, 0, 0, 0)))
                        }}
                        value={nextActivityDueDate}
                        popperPlacement="bottom-start"
                      />
                      <AlertPopover options={alertPopoverOptions} show={!!infoAlert && infoAlert.location === "nextActivityDue"}>
                        {isTransitioning && <div className="h-4 mx-2"><Loading /></div>}
                      </AlertPopover>
                    </div>
                  )}
                </div>
              )}
              {status === "Archived" && (
                <p className="italic">{`Household was archived on ${customDateFormat(archivedAt)}`}</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-5 items-center">
          {status === "New results available" && game && (
            <>
              {!userProfile?.isAssociate && (
                <Popover
                  active={theme.tours.gameComplete.show && step.gameComplete === "Present results"}
                  content={
                    <>
                      <p className="font-semibold text-main-500 text-sec">Present the household’s results to them when you next meet.</p>
                      <div className="flex flex-row justify-between items-center mt-5">
                        <p className="text-main-400 text-sec">{progress.gameComplete + 1} of {tourSteps.gameComplete.length}</p>
                        <button className="btn btn-secondary btn-xs w-21" onClick={() => completeStep({ gameComplete: "Present results" })}>
                          {isLastStep("gameComplete") ? "Got it" : "Next"}
                        </button>
                      </div>
                    </>
                  }
                  position="bottom"
                  onClose={() => completeStep({ gameComplete: "Present results" })}
                >
                  <Link
                    className="btn btn-medium btn-primary w-44 text-center animate-highlight-ping"
                    to={`/households/${household._id}/rm/overview`}
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      trackViewEvent({ action: "click", category: "present_results", label: household._id })
                      window.open(`/households/${household._id}/rm/overview`)
                    }}
                  >
                    Present results
                  </Link>
                </Popover>
              )}
              {theme.pages.profile.sections.clientStatus.showButtons.presentResults && game?.gameReportUrl && (
                <Popover
                  active={theme.tours.gameComplete.show && step.gameComplete === "Preview report"}
                  content={
                    <>
                      <p className="font-semibold text-main-500 text-sec">Preview your household’s risk profile before you meet.</p>
                      <div className="flex flex-row justify-between items-center mt-5">
                        <p className="text-main-400 text-sec">{progress.gameComplete + 1} of {tourSteps.gameComplete.length}</p>
                        <button
                          className="btn btn-secondary btn-xs w-21"
                          onClick={() => completeStep({ gameComplete: "Preview report" })}
                        >
                          {isLastStep("gameComplete") ? "Got it" : "Next"}
                        </button>
                      </div>
                    </>
                  }
                  position="bottom"
                  onClose={() => completeStep({ gameComplete: "Preview report" })}
                >
                  <Link
                    className="btn btn-secondary btn-medium w-44 text-center"
                    to={game?.gameReportUrl}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => trackViewEvent({ action: "click", category: "check_results", label: household._id })}
                  >
                    Preview insights
                  </Link>
                </Popover>
              )}
            </>
          )}
          {household.status !== "Archived" && game && game[game.gameType!]?.results && game.summaryDate && (
            <button className="btn btn-secondary btn-medium w-44" onClick={() => setShowReprofileModal(true)}>
              Re-profile
            </button>
          )}
          {status === "Archived" && (
            household.externalId ? (
              <ProviderAlert household={household}>
                <span>This household was managed in {household.externalId.split(":")[0]}. To un-archive, please re-create the partner relationship in {household.externalId.split(":")[0]}.</span>
              </ProviderAlert>
            ) : (
              <button className="btn btn-primary btn-medium w-44" onClick={() => setShowUnArchiveModal(true)}>
                Un-archive
              </button>
            )
          )}
        </div>
      </div>

      <AnimatePresence>
        {showUnArchiveModal && <UnArchiveConfirmationModal clientOrHousehold={household} onClose={() => setShowUnArchiveModal(false)} />}
        {showReprofileModal && <ReprofileConfirmationModal household={household} game={game} onClose={() => setShowReprofileModal(false)} />}
      </AnimatePresence>
    </>
  )
}

export default HouseholdGameStatusRow