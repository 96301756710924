import format from "date-fns/format"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"
import { computeGoalAchievability } from "../../../../api/rm/goals"
import chevronDown from "../../../../assets/icons/chevron-down.svg"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import { FEATURE_WEALTH_AND_INCOME_RANGE } from "../../../../config/features"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../contexts/FirmContext"
import { latest } from "../../../../lib/clients"
import { Client, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import SelectPortfolio, { createOptions, Options } from "../components/SelectPortfolio/SelectPortfolio"
import { GoalExplorerStatuses } from "../utils/validation"
import RetirementIncomeForm from "./RetirementIncomeForm"
import {
  createFormValues,
  createRetirementIncomeRequest,
  RetirementIncomeFormErrors,
  RetirementIncomeFormValues,
  validateRetirementDrawdownForm,
  validateRetirementIncomeForm
} from "./RetirementIncomeUtils"
import { deepEqual } from "../../../../lib/utils"
import { useBlocker, useLocation } from "react-router"
import { usePreventUnload } from "../../../../hooks/usePreventUnload"
import { AnimatePresence } from "framer-motion"
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal"

interface Props {
  client?: Client
  household?: Household
  outsideIM?: boolean
  onDelete?: () => void
}

const RetirementIncome = ({ client, household, outsideIM, onDelete }: Props) => {
  const { firm } = useContext(FirmContext)
  const location = useLocation()
  const currentState = location.state || {}
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const clientOrHousehold = client ?? household
  const goal = useMemo(() => clientOrHousehold?.goals?.goalDetails?.find((goal) => goal.type === "retirementIncome"), [clientOrHousehold?.goals?.goalDetails])
  const [values, setValues] = useState<RetirementIncomeFormValues>(() => createFormValues({ client, household, goal }))
  const [status, setStatus] = useState<GoalExplorerStatuses>("init")
  const [errors, setErrors] = useState<RetirementIncomeFormErrors>({})
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const goalType = "retirementIncome"
  const [options, setOptions] = useState<Options | undefined>(() => createOptions({ goalType, clientOrHousehold }))
  const [optionsWithRange, setOptionsWithRange] = useState<Options | undefined>(() => createOptions({ goalType, clientOrHousehold }))
  const [showRiskComfort, setShowRiskComfort] = useState(true)
  const [showIncomeRange, setShowIncomeRange] = useState(false)
  const [displayType, setDisplayType] = useState<"chart" | "list">("chart")
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const dropDownTrigger = useRef<HTMLDivElement>(null)
  const [initialValues, setInitialValues] = useState<RetirementIncomeFormValues>(() => createFormValues({ client, household, goal }))

  useEffect(() => {
    setShouldValidate(currentState.shouldValidate ?? false)
  }, [currentState.shouldValidate])

  const calculateResults = useCallback(
    (goalType: InvestmentGoal) => {
      const values = createFormValues({
        client: client ? (clientOrHousehold as Client) : undefined,
        household: household ? (clientOrHousehold as Household) : undefined,
        goal
      })
      const { isValid } = goalType === "retirementDrawdown" ? validateRetirementDrawdownForm({ values }) : validateRetirementIncomeForm({ values })
      if (clientOrHousehold && isValid) {
        setStatus("calculating")
        computeGoalAchievability({
          goalType,
          advisorId: clientOrHousehold.advisorId,
          clientId: client && clientOrHousehold._id,
          householdId: household && clientOrHousehold._id
        })
          .then((res) => {
            setOptions(
              createOptions({
                goalType,
                clientOrHousehold,
                portfolios: res.results,
                isRange: false,
                disPortfolios: res.disPortfolios,
                legacyPortfolios: res.legacyPortfolios,
                goal
              })
            )
            setOptionsWithRange(createOptions({ goalType, clientOrHousehold, portfolios: res.results, isRange: true, goal }))
            setErrorMessage(undefined)
            setStatus("success")
          })
          .catch((error) => {
            setStatus("error")
            setErrorMessage("An error occurred. Please try again.")
            console.error("error calculating goal achievability", error)
          })
      }
    },
    [client, clientOrHousehold, goal, household]
  )

  const update = useCallback(() => {
    if (client) {
      const member = values.members[0]
      const updateRequest: ClientUpdateRequest = createRetirementIncomeRequest({ client, values, goalType, goal }) as ClientUpdateRequest
      const dob = member.dob ? format(member.dob, "yyyy-MM-dd") : member.dobRaw ?? undefined
      if (dob !== client.dob) {
        updateRequest.dob = dob
      }
      if (values.alreadyRetired) {
        updateRequest.alreadyRetired = true
        updateRequest.retirementDate = null
      } else if (member.retirementDate) {
        updateRequest.alreadyRetired = false
        updateRequest.retirementDate = format(member.retirementDate, "yyyy-MM-dd")
      }
      return updateClient(client._id, updateRequest)
        .then(() => {
          setErrorMessage(undefined)
          setStatus("init")
        })
        .catch((error) => {
          setStatus("error")
          setErrorMessage("An error occurred. Please try again.")
          console.error("error updating client details", error)
        })
    } else if (household) {
      const updateRequest: HouseholdUpdateRequest = createRetirementIncomeRequest({ values, goalType, household, goal }) as HouseholdUpdateRequest
      const memberUpdates: ClientUpdateRequest[] = values.members.map(({ dob, dobRaw, retirementDate }, i) => {
        const nextMemberUpdates: { dob?: string; retirementDate?: string | null } = {}
        const nextDob = dob ? format(dob, "yyyy-MM-dd") : dobRaw ?? undefined
        if (nextDob !== household?.members[i].client.dob) {
          nextMemberUpdates.dob = nextDob
        }
        if (values.alreadyRetired) {
          updateRequest.alreadyRetired = true
          nextMemberUpdates.retirementDate = null
        } else if (retirementDate) {
          updateRequest.alreadyRetired = false
          nextMemberUpdates.retirementDate = format(retirementDate, "yyyy-MM-dd")
        }
        return nextMemberUpdates
      })
      return updateHousehold(household._id, memberUpdates, updateRequest)
        .then(() => {
          setErrorMessage(undefined)
          setStatus("init")
        })
        .catch((error) => {
          setStatus("error")
          setErrorMessage("An error occurred. Please try again.")
          console.error("error updating client details", error)
        })
    }
  }, [client, goal, goalType, household, updateClient, updateHousehold, values])

  const onSubmit = useCallback(() => {
    setShouldValidate(true)
    const { isValid } = values.alreadyRetired ? validateRetirementDrawdownForm({ values }) : validateRetirementIncomeForm({ values })
    if (isValid) {
      setStatus("updating")
      update()
    }
  }, [update, values])

  const onChange = (values: Partial<RetirementIncomeFormValues>) => {
    setValues((prev) => ({
      ...prev,
      ...values
    }))
  }

  useEffect(() => {
    setValues(createFormValues({ client, household, goal }))
    setInitialValues(createFormValues({ client, household, goal }))
  }, [client, goal, household])

  useEffect(() => {
    if (status === "init") {
      const values = createFormValues({ client, household, goal })
      setValues(values)
      const { isValid } = values.alreadyRetired ? validateRetirementDrawdownForm({ values }) : validateRetirementIncomeForm({ values })
      if (isValid) {
        setStatus("calculating")
        calculateResults(values.alreadyRetired ? "retirementDrawdown" : "retirementIncome")
      }
    }
  }, [calculateResults, client, goal, household, status])

  useEffect(() => {
    if (shouldValidate) {
      const { errors } = values.alreadyRetired ? validateRetirementDrawdownForm({ values: values }) : validateRetirementIncomeForm({ values: values })
      setErrors(errors)
    }
  }, [firm, values, shouldValidate])

  useEffect(() => {
    if (values.alreadyRetired) {
      setShouldValidate(true)
    }
  }, [values.alreadyRetired])

  const game = latest(clientOrHousehold!, "risk")

  const hasChanged = useMemo(() => !deepEqual(initialValues, values), [initialValues, values])
  const blocker = useBlocker(() => hasChanged)
  usePreventUnload(hasChanged)

  return (
    <div className="absolute w-full h-full flex flex-row">
      <div className="w-75 h-full shrink-0 bg-surface-200 flex flex-col overflow-hidden">
        <div className="grow flex flex-col overflow-hidden">
          <RetirementIncomeForm
            client={client}
            errors={errors}
            household={household}
            onChange={onChange}
            onSubmit={onSubmit}
            setStatus={setStatus}
            status={status}
            values={values}
            goalType={values.alreadyRetired ? "retirementDrawdown" : "retirementIncome"}
            outsideIM={outsideIM}
            calculateResults={(goalType) => {
              calculateResults(goalType!)
            }}
            goal={goal}
            onDelete={onDelete}
          />
        </div>
      </div>
      <div className="w-full relative grow overflow-auto no-scrollbar bg-white">
        <div className="w-full h-full flex flex-col items-center justify-stretch">
          <SelectPortfolio
            displayType={displayType}
            displayTypes={(type) => setDisplayType(type)}
            errorMessage={errorMessage}
            goalType={values.alreadyRetired ? "retirementDrawdown" : "retirementIncome"}
            message={!options && status === "init" ? "Please update your plan details" : undefined}
            options={showIncomeRange && !values.alreadyRetired ? optionsWithRange : options}
            client={client}
            household={household}
            isRiskComfort={showRiskComfort}
            isRange={showIncomeRange}
            isUpdating={status === "updating" || status === "calculating"}
            goal={goal}
            outsideIM={outsideIM}
          >
            {FEATURE_WEALTH_AND_INCOME_RANGE && displayType === "chart" && options && !clientOrHousehold?.alreadyRetired && (
              <div className="relative w-max">
                <div className="flex gap-x-1 cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)} ref={dropDownTrigger}>
                  <p className="text-xs text-interactive-500 leading-1 font-bold">Show</p>
                  <img className="px-1" src={chevronDown} alt="chevron-down" />
                </div>

                {isMenuOpen && (
                  <Dropdown className="w-full" overlayClassName="w-max p-3 mt-2 z-50" handleClose={() => setIsMenuOpen(false)} trigger={dropDownTrigger}>
                    {game?.risk?.results && (
                      <Checkbox
                        className="mb-1 p-0"
                        name="risk-comfort"
                        label="Risk comfort"
                        checked={showRiskComfort}
                        onChange={(e) => setShowRiskComfort(e.target.checked)}
                      />
                    )}
                    <Checkbox
                      className="p-0"
                      name="income-range"
                      label="Income range"
                      checked={showIncomeRange}
                      onChange={(e) => setShowIncomeRange(e.target.checked)}
                    />
                  </Dropdown>
                )}
              </div>
            )}
          </SelectPortfolio>
          {!options && (status === "updating" || status === "calculating") && <Loading />}
        </div>
      </div>
      <AnimatePresence>
        {blocker.state === "blocked" && <UnsavedChangesModal onClose={blocker.reset} onCancel={blocker.reset} onConfirm={blocker.proceed} />}
      </AnimatePresence>
    </div>
  )
}

export default RetirementIncome
