import { Outlet, Route, Routes } from "react-router"
import GoalProjectorPage from "../advisor/GoalProjectorPage"

const RMJourney = () => (
  <div className="relative pg-ctr w-full h-full">
    <Outlet />
    <Routes>
      <Route path="set-goal" element={<GoalProjectorPage />} />
      <Route path="goal" element={<GoalProjectorPage />} />
      {/* pointing these above 2 routes to the new goal projector tab, in case someone bookmarks them :shrug */}
      <Route path="goal-projector" element={<GoalProjectorPage />} />
    </Routes>
  </div>
)

export default RMJourney
