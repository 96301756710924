import { useContext, useEffect, useState } from "react"
import { useMutation } from "react-query"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import ErrorMessage from "../../../../components/Error/ErrorMessage"
import Modal from "../../../../components/Modal/Modal"
import RadioButton from "../../../../components/RadioButton/RadioButton"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { Client } from "../../../../models/Client"
import { Household } from "../../../../models/Household"

interface Props {
  onClose: () => void
  client?: Client
  household?: Household
}

export const timeframeOptions = [
  { id: "low", label: "Less than 1 year", value: 0.5 },
  { id: "medium", label: "1-2 years", value: 2 },
  { id: "high", label: "3-6 years", value: 5 },
  { id: "very-high", label: "More than 7 years", value: 10 }
]

export const defaultTimeframe = 5

const InvestmentTimeframeModal = ({ onClose, client, household }: Props) => {
  const clientOrHousehold = client ?? household
  const [timeframe, setTimeframe] = useState<number>(clientOrHousehold?.investmentTimeframe ?? defaultTimeframe)
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)

  const { mutate: updateTimeframe, status } = useMutation<Client | Household | null>({
    mutationFn: () =>
      household ? updateHousehold(household!._id, [], { investmentTimeframe: timeframe }) : updateClient(client!._id, { investmentTimeframe: timeframe }),
    onSuccess: () => onClose(),
    onError: (err: any) => {
      console.error("Error updating timeframe", err)
    }
  })

  useEffect(() => {
    setTimeframe(clientOrHousehold?.investmentTimeframe ?? defaultTimeframe)
  }, [clientOrHousehold?.investmentTimeframe])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value)
    if (!isNaN(value)) {
      setTimeframe(value)
    }
  }

  return (
    <Modal handleClose={onClose} className="max-w-xl w-full">
      <div className="w-full">
        <h2 className="text-h2 font-semibold pb-6">Edit investment timeframe</h2>
        <div>
          <fieldset className="flex flex-col">
            <legend className="text-sec font-semibold pb-1">How long do you want to stay invested for?</legend>
            {timeframeOptions.map((option) => (
              <RadioButton
                key={option.id}
                checked={timeframe === option.value}
                id={option.id}
                label={option.label}
                name="investment-timeframe"
                onChange={(e) => onChange(e)}
                value={String(option.value)}
                className="py-1"
              />
            ))}
          </fieldset>
        </div>
        <div role="alert">{status === "error" && <ErrorMessage id="edit-investment-timeframe" message="Error updating the value" />}</div>
        <div className="flex justify-center gap-5 mt-6">
          <button type="button" onClick={onClose} className="btn btn-secondary btn-medium w-44">
            Cancel
          </button>
          <button className="btn btn-primary btn-medium w-44" disabled={!timeframe || status === "loading"} onClick={() => updateTimeframe()}>
            {status === "loading" ? <Loading type="dots" /> : "Update"}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default InvestmentTimeframeModal
