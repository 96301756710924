import { t } from "@lingui/macro"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client } from "../../../models/Client"
import Page from "./Page"
import { useMemo } from "react"
import { clientTopCause } from "../components/Profile/causeScores"

const BusinessInvestment = ({ page, client }: { page: number; client: Client }) => {
  const theme = useTheme()
  const cause = useMemo(() => clientTopCause(client, theme), [client, theme])

  return (
    <Page page={page} title={t({ id: "print-report-page-header-title" })}>
      <div className="mx-9 h-full">
        <h2 className="mb-8 text-[22px]">We do business the way you want to invest</h2>

        <div className="border-b" style={{ borderBottomColor: "#8C8E90" }}>
          <h2 className="mb-2 font-semibold text-sec">{cause?.businessInvestmentApproach?.title}</h2>
          <p className="mb-4 font-normal text-sec leading-5">{cause?.businessInvestmentApproach?.description}</p>
          {theme.reports?.businessInvestment?.commitmentInfoLink && (
            <div className="mb-4 text-sec">
              <a className="flex items-center font-medium" style={{ color: "#096183" }} href={theme.reports?.businessInvestment?.commitmentInfoLink}>
                See our approach
                <img className="w-4 h-4 ml-1" src={theme.icons?.chevronRight} alt="" aria-hidden />
              </a>
            </div>
          )}
        </div>
        <div className="border-b mt-8" style={{ borderBottomColor: "#8C8E90" }}>
          <h2 className="mb-2 font-semibold text-sec">Our approach to sustainability</h2>
          <p className="mb-2 font-normal text-sec leading-5">
            We are committed to acting in its clients’ best interests consistent with our fiduciary duty, client goals, and legal requirements. We offer
            products that seek to meet financial goals while also meeting client objectives for sustainable outcomes through our sustainable investing
            solutions.
          </p>
          <p className="mb-4 font-normal text-sec leading-5">
            We also offer strategies and products singularly focused on financial goals and objectives. As we strive to meet these objectives, managing
            financially material environmental, social and governance (ESG) risks and opportunities is an important part of our investment processes.
          </p>
          {theme.reports?.businessInvestment?.approachLink && (
            <div className="mb-7 text-sec flex items-center">
              <a
                className="flex items-center font-medium"
                style={{ color: "#096183" }}
                href={theme.reports?.businessInvestment?.approachLink}
                aria-label="Learn more about our approach to sustainability"
              >
                Learn more
                <img className="w-4 h-4 ml-1" src={theme.icons?.chevronRight} alt="" aria-hidden />
              </a>
            </div>
          )}
        </div>
        <div className="mt-8">
          <h2 className="mb-2 font-semibold text-sec">About us</h2>

          <p className="mb-4 font-normal text-sec leading-5">
            As a global investment manager, our goal is to help our clients achieve their financial objectives. For more than a century, through changing
            markets, the individuals, families, businesses and institutions we serve have relied on our perspective to help them build stronger portfolios.
          </p>
          {theme.reports?.businessInvestment?.aboutLink && (
            <div className="mb-4 text-sec flex items-center">
              <a
                className="flex items-center font-medium"
                style={{ color: "#096183" }}
                href={theme.reports?.businessInvestment?.aboutLink}
                aria-label="Find out more about our approach to sustainability"
              >
                Find out more about us
                <img className="w-4 h-4 ml-1" src={theme.icons?.chevronRight} alt="" aria-hidden />
              </a>
            </div>
          )}
        </div>
      </div>
    </Page>
  )
}
export default BusinessInvestment
