import differenceInDays from "date-fns/differenceInDays"
import differenceInMonths from "date-fns/differenceInMonths"
import { AnimatePresence } from "framer-motion"
import React, { PropsWithChildren, useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { AppContext } from "../../contexts/AppContext"
import { ThemeContext } from "../../contexts/ThemeContext"
import { UserPoliciesContext } from "../../contexts/UserPoliciesContext"
import useStatusCounts from "../../hooks/useStatusCounts"
import FeedbackModal from "../../pages/advisor/components/FeedbackModal/FeedbackModal"
import ImportReminderModal from "../../pages/advisor/components/ImportReminder/ImportReminderModal"
import Onboarding from "../../pages/advisor/tour/Onboarding"
import Modal from "../Modal/Modal"
import UserPolicies from "../UserPolicies/UserPolicies"

type ModalTypes = "feedback" | "importReminder" | "onboarding" | "policies" | null

const ModalPresenter: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const { theme, isLoading: isThemeLoading } = useContext(ThemeContext)
  const profile = useContext(AppContext)
  const navigate = useNavigate()
  const { firmHasBilling, isBillingAccepted, isTosAccepted, isPrivacyPolicyAccepted } = useContext(UserPoliciesContext)
  const now = new Date()

  const { userProfile, updateUserProfile } = profile

  const { data: statusCounts, isSuccess } = useStatusCounts()

  const daysSinceAccountCreation = useMemo(
    () => differenceInDays(now, new Date(userProfile?.createdAt ?? now)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userProfile?.createdAt]
  )
  const monthsSinceAccountCreation = useMemo(
    () => differenceInMonths(now, new Date(userProfile?.createdAt ?? now)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userProfile?.createdAt]
  )

  // Feedback
  const lastFeedback = userProfile?.feedback
  //check 12 months have passed from last feedback modal shown
  const monthsSinceLastFeedback = useMemo(
    () => differenceInMonths(now, new Date(lastFeedback?.timestamp ?? now)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastFeedback?.timestamp]
  )
  // check 7 days from last presentation in case of snoozed
  const daysSinceLastFeedback = useMemo(
    () => differenceInDays(now, new Date(lastFeedback?.timestamp ?? now)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastFeedback?.timestamp]
  )
  const shouldGiveFeedback =
    import.meta.env.VITE_APP_FEATURE_NPS_FEEDBACK === "true" &&
    monthsSinceAccountCreation >= 3 && // check if feedback should be shown after 3 months from account creation
    (!lastFeedback ||
      ((lastFeedback?.status === "success" || lastFeedback?.status === "dismissed") && monthsSinceLastFeedback >= 12) ||
      (lastFeedback?.status === "snoozed" && daysSinceLastFeedback >= 7))

  // Import reminder
  const numClients = statusCounts?.filter((d) => d.status !== "Archived").reduce((acc, item) => acc + item.count, 0) ?? 0
  const lastImportReminder = userProfile?.importReminder
  const monthsSinceLastImportReminder = useMemo(
    () => differenceInMonths(now, new Date(lastImportReminder?.timestamp ?? now)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastImportReminder?.timestamp]
  )
  // Only advisors (not associates) whose accounts are older than 2 weeks with fewer than 50 clients should see this reminder
  const shouldShowImportReminder =
    import.meta.env.VITE_APP_FEATURE_IMPORT_REMINDER === "true" &&
    !userProfile?.isAssociate &&
    isSuccess &&
    numClients < 50 &&
    daysSinceAccountCreation >= 14 &&
    (!lastImportReminder || monthsSinceLastImportReminder >= 3) // Min 3 months since last reminder before showing again

  const shouldShowUserPoliciesModal =
    (!isThemeLoading && theme.policies.billing.enabled && firmHasBilling && !isBillingAccepted) ||
    (!isThemeLoading && theme.policies.tos.enabled && !isTosAccepted) ||
    (!isThemeLoading && theme.policies.privacy.enabled && !isPrivacyPolicyAccepted)

  const shouldShowOnboarding = !userProfile?.tourState?.welcome

  const modals: ModalTypes[] = useMemo(
    () =>
      [
        shouldShowOnboarding ? "onboarding" : null,
        shouldShowUserPoliciesModal ? "policies" : null,
        shouldGiveFeedback ? "feedback" : null,
        shouldShowImportReminder ? "importReminder" : null
      ].filter((_) => _) as ModalTypes[],
    [shouldGiveFeedback, shouldShowImportReminder, shouldShowOnboarding, shouldShowUserPoliciesModal]
  )

  const currentModal = modals.length ? modals[0] : null

  const noop = () => {}
  // console.log("insights from modal presenter", { currentModal, lastFeedback, daysSinceLastFeedback, monthsSinceAccountCreation, monthsSinceLastFeedback, shouldGiveFeedback })
  return (
    <>
      {children}
      <AnimatePresence>
        {currentModal === "onboarding" && (
          <Modal
            className="onboarding max-w-screen-md w-modal"
            contentClassName="!px-0 !py-0"
            handleClose={() => updateUserProfile({ tourState: { welcome: new Date() } })}
          >
            <Onboarding handleClose={noop} />
          </Modal>
        )}
        {currentModal === "policies" && (
          <Modal className="max-w-screen-md w-modal" contentClassName="!px-0 !py-0">
            <UserPolicies handleClose={noop} />
          </Modal>
        )}
        {currentModal === "feedback" && (
          <Modal className="max-w-screen-md w-modal" handleClose={() => updateUserProfile({ feedback: { timestamp: new Date(), status: "dismissed" } })}>
            <FeedbackModal handleClose={noop} />
          </Modal>
        )}
        {currentModal === "importReminder" && (
          <Modal className="max-w-screen-md w-modal" handleClose={() => updateUserProfile({ importReminder: { timestamp: new Date(), status: "dismissed" } })}>
            <ImportReminderModal
              handleConfirm={() => {
                updateUserProfile({ importReminder: { timestamp: new Date(), status: "success" } })
                navigate("/import-clients")
              }}
              handleClose={() => updateUserProfile({ importReminder: { timestamp: new Date(), status: "snoozed" } })}
            />
          </Modal>
        )}
      </AnimatePresence>
    </>
  )
}

export default ModalPresenter
