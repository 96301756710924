import { Tour } from "../contexts/TourController"
import { GameType, GoalType } from "../models/Client"
import { ThemeID } from "../models/Theme"
import { AssetTypeColor } from "./assetClasses"
import defaults from "./default"

export type Reports = "default" | "risk" | "esg" | "risk:pre" | "risk:post" | "esg:pre" | "esg:post" | "pfs"
export type ReportPage =
  | "cover"
  | "advice"
  | "summary"
  | "decisionPattern"
  | "riskDimensions"
  | "riskProfile"
  | "riskCharts"
  | "comfortMatch"
  | "projectorChart"
  | "projectorTable"
  | "sustainableCover"
  | "sustainabilityFocus"
  | "sustainabilityValues"
  | "sustainabilityCommitment"
  | "goalCover"
  | "goalSummary"
  | "investorPersonality"
  | "investmentProjection"
  | "selectedPortfolio"
  | "valuesAppendix1"
  | "valuesAppendix2"
  | "topThemes"
  | "investingChampion"
  | "altruismScores"
  | "portfolioAnalyser"
  | "sustainabilityMetrics"
  | "fundsMatch"
  | "businessInvesment"
  | "appendix1"
  | "appendix2"
  | "appendix3"
  | "disclaimer"
  | "sustainableInvestingDisclaimer"
  | "sustainableInvestingDisclaimer2"
  | "pfsCover"
  | "pfsNetWorth"
  | "pfsIncomeExpenses"
export type Status = "New client" | "New household" | "Activity pending" | "New results available" | "Up to date" | "Up for review" | "Archived" // Combination of ClientStatus and HouseholdStatus
export type SupportedLocales = "en-AU" | "en-US" | "en-GB" | "en"

export type ResultsTabSection = "viewReport" | "riskPersonaSummary" | "comfortZone" | "attitudeToRisk" | "sensitivityToLoss" | "scoreTrends" | "decisionPattern" | "valuesPersonaSummary" | "causeScores" | "interests" | "investmentThemes"

export interface Item {
  title: string
  description?: string
}

export interface Goal {
  description: string
  icon?: string
}

export interface DevGoals {
  description?: string
  items: Goal[]
}
export interface ThemeItem {
  title: string
}

export type Cause = {
  id: string
  title: string
  description?: string
  barColor?: string
  icon?: string
  secondaryIcon?: string
  returnsIcon?: string
  persona?: {
    name: string
    title: string
    description: string
    icon?: string
    image?: string
    link?: string
  }
  themeItems?: ThemeItem[]
  items?: Item[]
  learn?: {
    devGoals?: DevGoals
    footerImage?: string
    footerBgImage?: string
  }
  businessInvestmentApproach?: {
    title: string
    description: string
    url: string
  }
  color?: string
  isDefault?: boolean
  themeIndicatorTitle?: string
  report?: {
    topThemeDescription?: string
    investmentChampionPersonaTitle?: string
    investmentChampionTitle?: string
    icon?: string
    mindsetHeroImage?: string
  }
}

export type Theme = {
  id: ThemeID
  gameType: GameType
  causes?: {
    S1?: {
      [key: string]: Cause
    }
    S2?: {
      [key: string]: Cause
    }
  }
  games: {
    type: GameType
    color: string
    hero: string
    secondaryLogo: string
  }[]
  goals: {
    type: GoalType
    name?: string
    icon?: string
    color?: string[]
  }[]
  colors: {
    assetClassConfigurations: { [key: string]: AssetTypeColor }
    avatarColors: string[]
    avatarTextColor: string
    avatarColorByStatus: boolean
    esgDonutColors: { [key: string]: string[] }
    riskComfortChartColors: {
      dataPoints: string[]
      line: string
    }
    scoreChartColor: string
  }
  fingerprint?: {
    openGameLinksInline?: boolean
  }
  navbar: {
    links: {
      clients: boolean
      team: boolean
      import: boolean
      archive: boolean
    }
    moreDropdown: {
      show: boolean
      links: {
        import: boolean
        archive: boolean
      }
    }
    hamburgerMenu: {
      show: boolean
      links: {
        personalDetails: boolean
        twoFactorAuth: boolean
        changePassword: boolean
        contactSupport: boolean
        knowledgeHub: boolean
        billing: boolean
        logout: boolean
      }
    }
  }
  onboarding: {
    screens: {
      [key: number]: {
        title: string
        image: string
        description: string
      }
    }
  }
  icons?: {
    chevronRight?: string
    alertIcon?: string
  }
  pages: {
    clientsList?: {
      clientActions: {
        show: boolean
        links: {
          archive: boolean
          view: boolean
        }
      }
      columns: {
        primaryAdviser: boolean
      }
      filters?: Status[]
      icons?: {
        emptyList?: string
      }
    }
    createClient: {
      showClientTypeSelection: boolean
      individual?: {
        formFields: {
          showExistingClientSwitch: boolean
          showDOB: boolean
        }
      }
    }
    importModal?: {
      clientsTemplateLink?: string
      portfolioTemplateLink?: string
      icons?: {
        downloadImage?: string
        removeImage?: string
        uploadImage?: string
      }
      images?: {
        warningImage?: string
        successImage?: string
      }
    }
    login?: {
      firmLogo?: {
        alt?: string
        image?: string
      }
      heroImage?: string
    }
    profile: {
      multiActivityView: "list" | "cards"
      autoUpToDate?: boolean // changes the client status from "New results available" to "Up to date" when viewed
      modals?: {
        addClientEmail?: {
          heroImage?: string
        }
        reprofile?: {
          heroImage?: string
          successImage?: string
        }
        needsResults?: {
          heroImage?: string
        }
      }
      sections: {
        activityAndNotes: {
          show: boolean
          showBottomBorder: boolean
        }
        altruismScore: {
          show: boolean
          showBottomBorder: boolean
        }
        clientStatus: {
          show: boolean
          showBottomBorder: boolean
          showButtons: {
            presentResults: boolean
            previewReport: boolean
          }
          statusImg?: string
        }
        clientWarning: {
          show: boolean
          showBottomBorder: boolean
        }
        fundsMatch: {
          show: boolean
          showBottomBorder: boolean
        }
        personaSummary: {
          show: boolean
          showBottomBorder: boolean
        }
        portfolioCompare: {
          show: boolean
          showBottomBorder: boolean
          uploadNote?: {
            icon?: string
          }
        }
        regulatoryReporting: {
          show: boolean
          showBottomBorder: boolean
        }
        reportHistory: {
          show: boolean
          showBottomBorder: boolean
        }
        scoreTrends: {
          show: boolean
          showBottomBorder: boolean
        }
        selectedPortfolio: {
          show: boolean
          showBottomBorder: boolean
        }
      }
      sidebar: {
        isCollapsible: boolean
        expandCollapseIcon: string
        personalDetails: {
          age: boolean
          investmentAmount: boolean
        }
        menu: {
          clientProfile: boolean
          goalProjector?: boolean
          contact: boolean
          createHousehold: boolean
          editClientDetails: {
            showLink: boolean
            modal: {
              fields: {
                dateOfBirth: boolean
              }
            }
          }
          portfolioAnalyser: boolean
          results: boolean
        }
      }
      riskPersona?: {
        bgImage?: string
      }
      valuesPersona?: {
        bgImage?: string
      }
      resultsTabs?: {
        risk?: ResultsTabSection[]
        esg?: ResultsTabSection[]
      }
    }
    portfolioAnalyser?: {
      fundAllocation: {
        icons: {
          chevronDown: string
          percentageIcon: string
          deleteIcon: string
          undoIcon: string
          filterIcon: string
          successCheckmark: string
        }
      }
    }
    adviceOverview?: {
      hero?: string
    }
  }
  charts?: {
    attitudeToRisk?: {
      description?: {
        show?: boolean
      }
      note?: {
        show?: boolean
        icon?: boolean
      }
      showScoreAsDataPoint?: boolean
    }
    sensitivityToLoss?: {
      description?: {
        show?: boolean
      }
      note?: {
        show?: boolean
        icon?: boolean
      }
      showScoreAsDataPoint?: boolean
      showAsSpectrum?: boolean
    }
    portfolioDetail?: {
      dougnutChartStrokeWidth?: number
    }
    comfortMatch?: {
      highlightComfortZone?: boolean
      useAvatarIcons?: boolean
      showAsSpectrumGraph?: boolean
      showDescription?: boolean
      showRiskComfortAsBadge?: boolean
      showLegend?: boolean
    }
  }
  clientLogo?: string
  reports?: {
    waterMark?: string
    coverPage?: {
      disclaimer?: {
        show: boolean
      }
      logosBar?: {
        position: "top" | "bottom"
      }
      reportFlag?: {
        position: "top" | "bottom"
        showDate: boolean
        show: boolean
      }
      showDate?: boolean
      showLogo?: boolean
    }
    decisionPatternPage?: {
      description?: {
        show?: boolean
      }
      scienceIcon?: string
    }
    riskChartsPage?: {
      attitudeToRisk?: {
        note?: {
          icon?: string
          show: boolean
        }
        showPersonalisedDescription?: boolean
        showSubtitle?: boolean
      }
      sensitivityToLoss?: {
        note?: {
          icon?: string
          show: boolean
        }
        showPersonalisedDescription?: boolean
        showSubtitle?: boolean
      }
      lineChart?: {
        dataPointIcon?: string
        showLegend?: boolean
        showIcon?: boolean
        showTitle?: boolean
      }
      showPreferencesHeader?: boolean
    }
    comfortMatchPage?: {
      appendProfileToDescription?: boolean
      isComfortZone?: boolean
      showDescription?: boolean
      showDisclaimers?: boolean
      showLegend?: boolean
      listView?: {
        showHeader?: boolean
        showTitle?: boolean
        showInfo?: boolean
      }
      chartHeight?: number
      chartWidth?: number
      chartPadding?: { top: number; right: number; bottom: number; left: number }
    }
    investmentMeeting?: {
      disclaimer?: {
        content?: string[]
      }
    }
    topThemes?: {
      leavesIcon?: string
    }
    investingChampion?: {
      insightsLink?: string
      insightsImage?: string
    }
    businessInvestment?: {
      commitmentInfoLink?: string
      approachLink?: string
      aboutLink?: string
    }
    riskSummary?: {
      heroImage?: string
      hidePiechart?: boolean
      inconsistentIcon?: string
      noteImage?: string
      showDescription1?: boolean
      usePersonalisedProfileTitle: boolean
    }
    sustainabilityCommitment?: {
      readMoreLink?: string
    }
    sustainabilityValues?: {
      selectedInterests?: {
        show: boolean
        image: string
      }
    }
    sustainableInvestingDisclaimers?: {
      showMSCISection?: boolean
    }
    goalCover?: {
      logo: string
      heroImage: string
    }
    goalSummary?: {
      doughnutChartColor: {
        growth: string
        income: string
        cash: string
      }
      images?: {
        noWealthGoal?: string
      }
    }
    investorPersonality?: {
      portfolioThemes: {
        id: string
        heroImage: string
      }[]
    }
    header?: {
      showPageNumber: boolean
      showOnDisclaimerPage?: boolean
    }
    pages: Partial<{
      [key in Reports]: ReportPage[]
    }>
    footer?: {
      showDisclaimer?: boolean
      showFirmName?: boolean
      showOnDisclaimerPage?: boolean
      clientContactInfo?: {
        email?: string
        url?: string
      }
    }
    valuesMindset?: {
      show: boolean
      datapointIcon?: string
    }
    portfolioSpectrumGraph?: {
      heroImage?: string
      icon?: string
      pointColors: string[]
    }
  }
  statuses?: {
    [key in Status]: {
      classNames: string
    }
  }
  messages?: Partial<{
    [key in SupportedLocales]: {
      [key: string]: any
    }
  }>
  defaultLocale?: SupportedLocales
  tours: {
    [key in Tour]: {
      show: boolean
    }
  }
  policies: {
    billing: {
      link?: string
      markdown: {
        version: string
        content: string
      }
      enabled: boolean
    }
    tos: {
      markdown: {
        version: string
        content: string
      }
      enabled: boolean
    }
    privacy: {
      markdown: {
        version: string
        content: string
      }
      enabled: boolean
    }
  }
}

const spaces: { [key: string]: Promise<Theme> } = {
  ...(import.meta.env.VITE_APP_THEME_BOAML === "true" ? { boaml: import("./boaml").then((_) => _.default) } : ({} as any)),
  ...(import.meta.env.VITE_APP_THEME_JPMAM === "true" ? { jpmam: import("./jpmam").then((_) => _.default) } : ({} as any)),
  ...(import.meta.env.VITE_APP_THEME_LEMMI === "true" ? { lemmi: import("./lemmi").then((_) => _.default) } : ({} as any)),
  ...(import.meta.env.VITE_APP_THEME_DBS === "true" ? { dbs: import("./dbs").then((_) => _.default) } : ({} as any)),
  ...(import.meta.env.VITE_APP_THEME_MARKETINGDEMO === "true" ? { marketingdemo: import("./marketingdemo").then((_) => _.default) } : ({} as any)),
  ...(import.meta.env.VITE_APP_THEME_CP_US === "true" ? { cp_US: import("./cp_US").then((_) => _.default) } : ({} as any)),
  default: Promise.resolve(defaults)
}

const theme: (key?: ThemeID) => Promise<Theme> = (key = (import.meta.env.VITE_APP_THEME_DEFAULT as ThemeID) ?? "default") => {
  // TODO: smart merge if key is present and non default (deep object merge with the defaults)
  return spaces[key] ?? spaces[import.meta.env.VITE_APP_THEME_DEFAULT ?? "default"]
}

export function switchTheme(theme?: string) {
  // Remove existing theme class
  Array.from(document.body?.classList)
    ?.filter((className) => Object.keys(spaces).includes(className))
    ?.forEach((className) => document.body.classList.remove(className))

  if (theme) {
    // Add the class for the selected theme
    document.body?.classList?.add(theme)
  }
}

export default theme
