import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { ClientUpdateRequest } from "../../../../api/clients"
import { HouseholdUpdateRequest } from "../../../../api/households"
import { computeGoalAchievability } from "../../../../api/rm/goals"
import chevronDown from "../../../../assets/icons/chevron-down.svg"
import Checkbox from "../../../../components/Checkbox/Checkbox"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import { FEATURE_WEALTH_AND_INCOME_RANGE } from "../../../../config/features"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { FirmContext } from "../../../../contexts/FirmContext"
import { latest } from "../../../../lib/clients"
import { Client, GoalType, InvestmentGoal } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import RMJInvestmentGoalModal from "../../portfolioComfort/RMJInvestmentGoalModal/RMJInvestmentGoalModal"
import SelectPortfolio, { createOptions, Options } from "../components/SelectPortfolio/SelectPortfolio"
import { GoalExplorerStatuses } from "../utils/validation"
import WealthAccumulationForm from "./WealthAccumulationForm"
import { createFormValues, createWealthAccumulationRequest, GoalFormErrors, GoalFormValues, validateWealthAccumulationForm } from "./WealthAccumulationUtils"
import { deepEqual } from "../../../../lib/utils"
import { useBlocker, useLocation } from "react-router"
import { usePreventUnload } from "../../../../hooks/usePreventUnload"
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal"

interface Props {
  client?: Client
  household?: Household
  outsideIM?: boolean
  goalType?: GoalType
  goalId?: string
  onDelete?: () => void
}

const WealthAccumulation = ({ client, household, outsideIM, goalType = "wealthAccumulation", goalId = "", onDelete }: Props) => {
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const { firm } = useContext(FirmContext)
  const location = useLocation()
  const currentState = location.state || {}
  
  const [status, setStatus] = useState<GoalExplorerStatuses>("init")

  const [values, setValues] = useState<GoalFormValues>({})
  const [errors, setErrors] = useState<GoalFormErrors>({})

  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  const clientOrHousehold = client ?? household
  const [showRiskComfort, setShowRiskComfort] = useState(true)
  const [showWealthRange, setShowWealthRange] = useState(false)
  const [options, setOptions] = useState<Options | undefined>()
  const [optionsWithRange, setOptionsWithRange] = useState<Options | undefined>()
  const [displayType, setDisplayType] = useState<"chart" | "list">("chart")
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isInvestmentGoalModalOpen, setIsInvestmentGoalModalOpen] = useState(false)
  const dropDownTrigger = useRef<HTMLDivElement>(null)
  const [initialValues, setInitialValues] = useState<GoalFormValues>({})

  const goal = useMemo(
    () => clientOrHousehold?.goals?.goalDetails?.find((goal) => goal.type === goalType && goal.id === goalId),
    [clientOrHousehold?.goals?.goalDetails, goalId, goalType]
  )

  useEffect(() => {
    setStatus("init")
    setShouldValidate(currentState.shouldValidate ?? false)
    setOptions(undefined)
    setOptionsWithRange(undefined)
  }, [currentState.shouldValidate, goalType])

  const calculateResults = useCallback(
    (goalType: InvestmentGoal, clientOrHousehold?: Client | Household) => {
      if (clientOrHousehold) {
        computeGoalAchievability({
          goalType,
          advisorId: clientOrHousehold.advisorId,
          clientId: client && clientOrHousehold._id,
          householdId: household && clientOrHousehold._id,
          goalId: goal?.id
        })
          .then((res) => {
            setOptions(createOptions({ goalType: "wealthAccumulation", clientOrHousehold: clientOrHousehold ?? undefined, portfolios: res.results, isRange: false, goal }))
            setOptionsWithRange(createOptions({ goalType: "wealthAccumulation", clientOrHousehold: clientOrHousehold ?? undefined, portfolios: res.results, isRange: true, goal }))
            setErrorMessage(undefined)
            setStatus("success")
          })
          .catch((error) => {
            setStatus("error")
            setErrorMessage("An error occurred. Please try again.")
            console.error("error calculating goal achievability", error)
          })
      }
    },
    [client, goal, household]
  )

  const update = useCallback(() => {
    if (client) {
      const updateRequest: ClientUpdateRequest = createWealthAccumulationRequest({ client, values, goalType, goal }) as ClientUpdateRequest
      return updateClient(client._id, updateRequest)
        .then(() => {
          setErrorMessage(undefined)
          setStatus("init")
        })
        .catch((error) => {
          setStatus("error")
          setErrorMessage("An error occurred. Please try again.")
          console.error("error updating client details", error)
        })
    } else if (household) {
      const updateRequest: HouseholdUpdateRequest = createWealthAccumulationRequest({ values, goalType, household, goal }) as HouseholdUpdateRequest

      return updateHousehold(household._id, [{}, {}], updateRequest)
        .then(() => {
          setErrorMessage(undefined)
          setStatus("init")
        })
        .catch((error) => {
          setStatus("error")
          setErrorMessage("An error occurred. Please try again.")
          console.error("error updating client details", error)
        })
    }
  }, [client, goal, goalType, household, updateClient, updateHousehold, values])

  const onSubmit = useCallback(() => {
    setShouldValidate(true)
    const { isValid } = validateWealthAccumulationForm({ values: values })
    if (isValid) {
      setStatus("updating")
      update()
    }
  }, [values, update])

  const onChange = (values: Partial<GoalFormValues>) => {
    setValues((prev) => ({
      ...prev,
      ...values
    }))
  }

  useEffect(() => {
    if (status === "init") {
      const nextValues = createFormValues({ client, household, goal })
      setValues(nextValues)
      const { isValid } = validateWealthAccumulationForm({ values: nextValues })
      if (isValid) {
        setStatus("calculating")
        calculateResults(goalType, clientOrHousehold)
      }
    }
  }, [calculateResults, client, clientOrHousehold, firm, goal, goalType, household, status])

  useEffect(() => {
    if (shouldValidate) {
      const { errors } = validateWealthAccumulationForm({ values })
      setErrors(errors)
    } else {
      setErrors({})
    }
  }, [firm, values, shouldValidate])

  const game = latest(clientOrHousehold!, "risk")

  useEffect(() => {
    setInitialValues(createFormValues({ client, household, goal }))
  }, [client, goal, household])

  const hasChanged = useMemo(() => !deepEqual(initialValues, values), [initialValues, values])
  const blocker = useBlocker(() => hasChanged)
  usePreventUnload(hasChanged)

  return (
    <div className="absolute w-full h-full flex flex-row">
      <div className="w-75 h-full shrink-0 bg-surface-200 flex flex-col overflow-hidden">
        <div className="grow flex flex-col overflow-hidden">
          <WealthAccumulationForm
            errors={errors}
            onChange={onChange}
            onSubmit={onSubmit}
            values={values}
            status={status}
            goal={goal}
            client={client}
            household={household}
            outsideIM={outsideIM}
            onDelete={onDelete}
          />
        </div>
      </div>
      <div className="w-full relative grow overflow-auto no-scrollbar bg-white">
        <div className="w-full h-full flex flex-col items-center justify-stretch">
          <SelectPortfolio
            errorMessage={errorMessage}
            goalType={goalType}
            message={!options && status === "init" ? "Please update your plan details" : undefined}
            options={showWealthRange ? optionsWithRange : options}
            client={client}
            household={household}
            displayType={displayType}
            displayTypes={(type) => setDisplayType(type)}
            isRiskComfort={showRiskComfort}
            isRange={showWealthRange}
            isUpdating={status === "updating" || status === "calculating"}
            goal={goal}
            outsideIM={outsideIM}
          >
            {FEATURE_WEALTH_AND_INCOME_RANGE && displayType === "chart" && options && (
              <div className="relative w-max">
                <div className="flex gap-x-1 cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)} ref={dropDownTrigger}>
                  <p className="text-xs text-interactive-500 leading-1 font-bold">Show</p>
                  <img className="px-1" src={chevronDown} alt="chevron-down" />
                </div>

                {isMenuOpen && (
                  <Dropdown className="w-full" overlayClassName="w-max p-3 mt-2 z-50" handleClose={() => setIsMenuOpen(false)} trigger={dropDownTrigger}>
                    {game?.risk?.results && (
                      <Checkbox
                        className="mb-1 p-0"
                        name="risk-comfort"
                        label="Risk comfort"
                        checked={showRiskComfort}
                        onChange={(e) => setShowRiskComfort(e.target.checked)}
                      />
                    )}
                    <Checkbox
                      className="p-0"
                      name="wealth-range"
                      label="Wealth range"
                      checked={showWealthRange}
                      onChange={(e) => setShowWealthRange(e.target.checked)}
                    />
                  </Dropdown>
                )}
              </div>
            )}
          </SelectPortfolio>
          {!options && (status === "updating" || status === "calculating") && <Loading />}
        </div>
      </div>
      <AnimatePresence>
        {isInvestmentGoalModalOpen && (
          <RMJInvestmentGoalModal client={client} household={household} onClose={() => setIsInvestmentGoalModalOpen(false)} defaultGoal="retirementIncome" />
        )}
        {blocker.state === "blocked" && (
          <UnsavedChangesModal onClose={blocker.reset} onCancel={blocker.reset} onConfirm={blocker.proceed} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default WealthAccumulation
