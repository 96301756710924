import { useContext, useMemo, useState } from "react"
import { Client, GoalDetail, GoalsDetails } from "../../../../../../models/Client"
import { Household } from "../../../../../../models/Household"
import PortfolioDetail from "../../../PortfolioDetail"
import { FirmContext } from "../../../../../../contexts/FirmContext"
import edit from "../../../../../../assets/icons/edit.svg"
import plus from "../../../../../advisor/assets/images/plus-circle.svg"
import Modal from "../../../../../../components/Modal/Modal"
import EditPortfolioModal from "../../../EditPortfolioModal"
import { ClientHouseholdCacheContext } from "../../../../../../contexts/ClientHouseholdCacheContext"
import { tt } from "../../../../../../lib/translations"
import { latest } from "../../../../../../lib/clients"
import CardTitle from "../../../../../advisor/Results/Goals/components/CardTitle"
import RMJPortfolioComfortMoreInfoModal from "../../../../../rmjourney/portfolioComfort/RMJPortfolioComfortMoreInfoModal/RMJPortfolioComfortMoreInfoModal"

const SelectedInvestment = ({
  client,
  household,
  context = "profile",
  goal
}: {
  client?: Client
  household?: Household
  context?: "goals" | "profile"
  goal?: GoalDetail
}) => {
  const { firm } = useContext(FirmContext)

  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const clientOrHousehold = (client ?? household)!
  const status = clientOrHousehold.status
  const [portfolioUpdateState, setPortfolioUpdateState] = useState<"updating" | "success" | "error">()
  const [showEditPortfolioModal, setShowEditPortfolioModal] = useState(false)
  const [showMoreInfoModal, setShowMoreInfoModal] = useState(false)

  const currentPortfolio = useMemo(() => {
    if (!firm?.modelPortfolios) return undefined
    const portfolioID =
      context === "goals"
        ? clientOrHousehold.goals?.goalDetails?.find(({ type, id }) => type === goal?.type && id === goal?.id)?.selectedPortfolio
        : clientOrHousehold.currentPortfolio
    return firm.modelPortfolios.find(({ id }) => id === portfolioID)
  }, [firm?.modelPortfolios, clientOrHousehold, context, goal])

  const game = latest(clientOrHousehold, "risk")
  const riskComfort = game?.portfolioMappings?.find((p) => p.portfolio.id === currentPortfolio?.id)?.riskComfort
  const [currentGoal, setCurrentGoal] = useState<GoalDetail>()

  const comfortMatches = useMemo(() => {
    return [...((game?.portfolioMappings ?? firm?.modelPortfolios?.map((mp) => ({ portfolio: mp, riskComfort: 0 }))) || [])].sort((a, b) => {
      const riskComfortA = a.riskComfort ?? 0
      const riskComfortB = b.riskComfort ?? 0
      const riskDifference = riskComfortB - riskComfortA
      return riskDifference !== 0 ? riskDifference : (a.portfolio.sd || 0) - (b.portfolio.sd || 0)
    })
  }, [game, firm?.modelPortfolios])

  const comfortMatch = comfortMatches[0]

  const updateGoalPortfolios = (chosenPortfolios: GoalDetail[] | null, onSuccess: () => void, onError: (error: any) => void) => {
    const updatedGoals: GoalsDetails = {
      goalDetails:
        (household ?? client)?.goals?.goalDetails?.map((g) =>
          chosenPortfolios?.find((portfolio) => portfolio.id === g.id && portfolio.type === g.type)
            ? { ...g, selectedPortfolio: chosenPortfolios.find((portfolio) => portfolio.id === g.id && portfolio.type === g.type)?.selectedPortfolio }
            : g
        ) ?? []
    }
    if (household) {
      updateHousehold(household._id, [], { goals: updatedGoals }).then(onSuccess).catch(onError)
    } else if (client) {
      updateClient(client._id, { goals: updatedGoals }).then(onSuccess).catch(onError)
    } else {
      console.error("No household or client found to update")
      setPortfolioUpdateState("error")
    }
  }

  // Updates currentPortfolio field only
  const updateCurrentPortfolio = (chosenPortfolio: string | null, onSuccess: () => void, onError: (error: any) => void) => {
    if (household) {
      const memberUpdates = household.members.map(() => ({
        currentPortfolio: chosenPortfolio
      }))
      const householdUpdate = { currentPortfolio: chosenPortfolio }
      updateHousehold(household._id, memberUpdates, householdUpdate).then(onSuccess).catch(onError)
    } else if (client) {
      updateClient(client._id, { currentPortfolio: chosenPortfolio }).then(onSuccess).catch(onError)
    } else {
      console.error("No household or client found to update")
      setPortfolioUpdateState("error")
    }
  }

  const onUpdatePortfolio = (chosenPortfolios: string | GoalDetail[] | null) => {
    setPortfolioUpdateState("updating")

    const handleSuccess = () => {
      setPortfolioUpdateState("success")
      setShowEditPortfolioModal(false)
    }

    const handleError = (error: any) => {
      console.error("Error updating portfolio details", error)
      setPortfolioUpdateState("error")
    }

    if (typeof chosenPortfolios === "string" || chosenPortfolios === null) {
      updateCurrentPortfolio(chosenPortfolios, handleSuccess, handleError)
    } else {
      updateGoalPortfolios(chosenPortfolios, handleSuccess, handleError)
    }
  }

  const portfolioTitle = useMemo(() => {
    if (context === "goals") {
      return undefined
    } else if (context === "profile") {
      return `${household ? household.members.map((m) => m.client.firstName).join(" and ") : client?.firstName}'s portfolio`
    }
  }, [client?.firstName, context, household])

  const goalDetails = clientOrHousehold.goals?.goalDetails
  const sortedGoalDetails = useMemo(() => {
    if (!firm?.modelPortfolios || !goalDetails) return goalDetails

    return [...goalDetails].sort((a, b) => {
      const getR = (portfolioId: string | null | undefined) =>
        portfolioId ? firm.modelPortfolios?.find(({ id }) => id === portfolioId)?.r ?? -Infinity : -Infinity

      const rA = getR(a.selectedPortfolio)
      const rB = getR(b.selectedPortfolio)

      if (a.selectedPortfolio && !b.selectedPortfolio) return -1 // Selected comes first
      if (!a.selectedPortfolio && b.selectedPortfolio) return 1 // Non-selected goes last

      if (rA === rB) { // Compare priority when r values are the same
        const priorityOrder = { essential: 1, important: 2, aspirational: 3 } 
        const priorityA = a.priority && priorityOrder[a.priority] !== undefined ? priorityOrder[a.priority] : Infinity
        const priorityB = b.priority && priorityOrder[b.priority] !== undefined ? priorityOrder[b.priority] : Infinity
        return priorityA - priorityB
      }

      return rB - rA // Higher `r` comes first
    })
  }, [firm?.modelPortfolios, goalDetails])

  return (
    <div>
      {firm?.assetClasses && (currentPortfolio || (context === "profile" && (sortedGoalDetails?.length ?? 0) > 0)) ? (
        <div className="flex flex-col mt-6 gap-6">
          {context === "goals" ? (
            <>
              <div className="flex items-center">
                <p>
                  {household
                    ? tt({
                        id: `selected-investment-description-household-${context}`,
                        values: { clients: `${household.members.map((m) => m.client.firstName).join(" and ")}`, portfolio: currentPortfolio?.name }
                      })
                    : tt({
                        id: `selected-investment-description-client-${context}`,
                        values: { client: client?.firstName, portfolio: currentPortfolio?.name }
                      })}
                </p>
              </div>
              <PortfolioDetail portfolio={currentPortfolio} riskComfort={riskComfort} />
            </>
          ) : (
            <>
              {sortedGoalDetails?.length ?? 0 > 1 ? (
                <div className="flex flex-wrap justify-start gap-5">
                  {sortedGoalDetails?.map((goal, index) => {
                    const modelPortfolio = firm.modelPortfolios?.find(({ id }) => id === goal.selectedPortfolio)
                    return (
                      <div key={index} className="w-[278px]">
                        <PortfolioDetail
                          portfolio={modelPortfolio}
                          showBreakdown={false}
                          showPortfolioDescription={true}
                          handleClick={() => {
                            setCurrentGoal(goal)
                            setShowMoreInfoModal(true)
                          }}
                          title={<CardTitle goalDetails={goalDetails!} goal={goal} />}
                        />
                      </div>
                    )
                  })}
                </div>
              ) : (
                <PortfolioDetail
                  portfolio={currentPortfolio}
                  showBreakdown={false}
                  showPortfolioDescription={true}
                  title={portfolioTitle}
                  handleClick={() => {
                    setShowMoreInfoModal(true)
                  }}
                />
              )}
            </>
          )}
          <button
            className="btn btn-text btn-text-md text-sec font-bold w-fit flex items-center justify-center align-middle gap-1 text-interactive-600"
            onClick={() => setShowEditPortfolioModal(true)}
            disabled={status === "Archived"}
          >
            <img src={edit} alt="" aria-hidden />
            Edit investment risk level
          </button>
        </div>
      ) : (
        <button
          onClick={() => setShowEditPortfolioModal(true)}
          disabled={status === "Archived"}
          className="btn btn-text btn-text-md leading-4 flex items-center justify-center align-middle gap-1 w-fit mt-6"
        >
          <img src={plus} alt="" aria-hidden />
          Add investment
        </button>
      )}
      {showEditPortfolioModal && (
        <Modal className="w-modal" handleClose={() => setShowEditPortfolioModal(false)}>
          <EditPortfolioModal
            client={client}
            household={household}
            goals={
              context === "goals" && goal
                ? [goal] // Pass single goal as an array
                : context === "profile" && sortedGoalDetails && sortedGoalDetails.length > 0
                ? sortedGoalDetails
                : undefined
            }
            portfolioMappings={game?.portfolioMappings ?? firm?.modelPortfolios?.map((mp) => ({ portfolio: mp }))}
            onUpdate={onUpdatePortfolio}
            updateState={portfolioUpdateState}
            currentPortfolio={context === "goals" ? goal?.selectedPortfolio ?? "" : clientOrHousehold.currentPortfolio}
            onClose={() => setShowEditPortfolioModal(false)}
          />
        </Modal>
      )}
      {showMoreInfoModal && (
        <RMJPortfolioComfortMoreInfoModal
          assetClasses={firm?.assetClasses}
          comfortMatch={game?.played ? comfortMatch : undefined}
          onClose={() => {
            setCurrentGoal(undefined)
            setShowMoreInfoModal(false)
          }}
          portfolioMappings={game?.portfolioMappings ?? firm?.modelPortfolios?.map((mp) => ({ portfolio: mp }))}
          title={
            currentGoal !== undefined ? (
              <>
                <CardTitle goalDetails={goalDetails!} goal={currentGoal!} />
                {" asset allocation options"}
              </>
            ) : undefined
          }
        />
      )}
    </div>
  )
}

export default SelectedInvestment
