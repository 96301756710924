import { useParams } from "react-router"
import Loading from "../../components/ClientProfile/Loading/Loading"
import LoadingError from "../../components/ClientProfile/LoadingError/LoadingError"
import useClient from "../../hooks/useClient"
import useHousehold from "../../hooks/useHousehold"
import GoalsTab from "./Results/Goals/GoalsTab"

const GoalProjectorPage = () => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client, isClientLoading, clientLoadError } = useClient(clientId)
  const { household, isHouseholdLoading, householdLoadError } = useHousehold(householdId)
  const loadingError = clientLoadError ?? householdLoadError

  return (
    <div className="relative bg-white h-full text-main-500">
      {isClientLoading || isHouseholdLoading ? (
        <Loading />
      ) : (
        <>
          {client ?? household ? (
            <div className="h-full overflow-y-auto no-scrollbar">
              <GoalsTab client={client} household={household} />
            </div>
          ) : (
            <LoadingError message={(loadingError as any)?.response?.data ?? loadingError?.message ?? "Could not load data"} />
          )}
        </>
      )}
    </div>
  )
}

export default GoalProjectorPage
