import clsx from "clsx"
import { useEffect, useMemo, useRef, useState } from "react"
import { VictoryAxis, VictoryChart, VictoryContainer, VictoryLabel, VictoryLine, VictoryScatter, VictoryTooltip } from "victory"
import { useTheme } from "../../../../contexts/ThemeContext"
import { Client, PortfolioMapping } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import { AssetClass, SubAssetClass } from "../../../../models/InvestmentUniverse"
import infoIcon from "../../assets/images/info-icon.svg"
import ChartIconTooltip from "./components/ChartIconTooltip"
import ChartPoint from "./components/ChartPoint"

const getAdjustedMax = (value: number) => {
  if (value <= 20) {
    return Math.ceil(value / 2) * 2
  } else if (value > 20 && value <= 50) {
    return Math.ceil(value / 5) * 5
  } else {
    return Math.ceil(value / 20) * 20
  }
}

export interface Datum {
  allAssetClasses: {
    [key: string]: AssetClass | SubAssetClass
  }
  chartSize: {
    height: number
    width: number
  } | null
  fill: string
  isComfortMatch: boolean
  isCurrentInvestment: boolean
  label: string
  portfolioMapping: PortfolioMapping
  x: number
  y: number
}

interface Props {
  allAssetClasses: {
    [key: string]: AssetClass | SubAssetClass
  }
  client?: Client
  household?: Household
  portfolioMappings: PortfolioMapping[]
  comfortMatch?: PortfolioMapping
  forReport?: boolean
  isPostMeeting?: boolean
}

const RMJPortfolioComfortChart = ({ allAssetClasses, client, household, portfolioMappings, comfortMatch, forReport = false, isPostMeeting = false }: Props) => {
  const clientOrHousehold = (client ?? household)!
  const containerRef = useRef<HTMLDivElement>(null)
  const [size, setSize] = useState<{ height: number; width: number } | null>(null)
  const [isComfortZoneTooltipVisible, setIsComfortZoneTooltipVisible] = useState(false)
  const [comfortZoneTooltipX, setComfortZoneTooltipX] = useState<number>()
  const [matchCoordinates, setMatchCoordinates] = useState<{ x: number; y: number; width: number; comfortScore: number }[]>([])
  const [isResizing, setIsResizing] = useState(false)
  const theme = useTheme()
  const { dataPoints, line } = theme.colors.riskComfortChartColors

  const data: Datum[] = useMemo(() => {
    return (
      portfolioMappings.map((portfolioMapping, i) => {
        return {
          allAssetClasses,
          chartSize: size,
          fill: dataPoints[i],
          isComfortMatch: portfolioMapping.portfolio.id === comfortMatch?.portfolio.id,
          isCurrentInvestment: portfolioMapping.portfolio.id === clientOrHousehold.currentPortfolio,
          label: portfolioMapping.portfolio.name,
          portfolioMapping: portfolioMapping,
          x: portfolioMapping.portfolio.sd ?? 0,
          y: portfolioMapping.portfolio.r ?? 0
        }
      }) ?? []
    )
  }, [allAssetClasses, clientOrHousehold.currentPortfolio, comfortMatch?.portfolio.id, dataPoints, portfolioMappings, size])

  const maxXAxisValue: number = useMemo(() => Math.max(...portfolioMappings.map(({ portfolio }) => portfolio.sd ?? 0)), [portfolioMappings])
  const minXAxisValue = useMemo(() => Math.min(...portfolioMappings.map(({ portfolio }) => portfolio.sd ?? 0)), [portfolioMappings])
  const adjustedMaxXAxisValue: number = useMemo(() => getAdjustedMax(maxXAxisValue), [maxXAxisValue])

  const maxYAxisValue: number = useMemo(() => {
    const max = Math.ceil(Math.max(...portfolioMappings.map(({ portfolio }) => portfolio.r ?? 0)))

    return getAdjustedMax(max)
  }, [portfolioMappings])

  const [isComfortMatchTooltipVisible, setIsComfortMatchTooltipVisible] = useState(false)
  const [isCurrentInvestmentTooltipVisible, setIsCurrentInvestmentTooltipVisible] = useState(false)
  const [comfortMatchXY, setComfortMatchXY] = useState<{ x: number; y: number }>()
  const [currentInvestmentXY, setCurrentInvestmentXY] = useState<{ x: number; y: number }>()
  const [clientName, setClientName] = useState<string | null>(null)

  useEffect(() => {
    let timeout: NodeJS.Timeout
    const onResize = () => {
      clearTimeout(timeout)
      setIsResizing(true)
      timeout = setTimeout(() => {
        setIsResizing(false)
      }, 200)
      if (containerRef.current) {
        setSize({
          height: containerRef.current.offsetHeight,
          width: containerRef.current.offsetWidth
        })
      }
    }

    window.addEventListener("resize", onResize)
    onResize()

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [setIsResizing])

  const onComfortMatchMouseOver = (x: number, y: number, forClientName?: string) => {
    setClientName(forClientName ?? "")
    setIsComfortMatchTooltipVisible(true)
    setComfortMatchXY({ x, y })
  }

  const onCurrentInvestmentMouseOver = (x: number, y: number) => {
    setIsCurrentInvestmentTooltipVisible(true)
    setCurrentInvestmentXY({ x, y })
  }
  const xAxisLabels = useMemo(() => {
    const xAxisStart = Math.max(0, Math.floor(minXAxisValue - 0.5))
    const xAxisEnd = Math.ceil(maxXAxisValue + 0.5)
    const range = xAxisEnd - xAxisStart
    const tickInterval = Math.max(1, Math.ceil(range / 9))
    const numTicks = Math.ceil(range / tickInterval) + 1
    return Array.from({ length: numTicks }, (_, index) => xAxisStart + index * tickInterval)
  }, [minXAxisValue, maxXAxisValue])

  const chartPadding = forReport
    ? theme.reports?.comfortMatchPage?.chartPadding ?? { top: 50, right: 20, bottom: 60, left: 80 }
    : { top: 100, right: 60, bottom: 80, left: 90 }

  const comfortZoneHeightOffset = forReport ? 16 : 32

  const comfortZoneArea = useMemo(() => {
    if (matchCoordinates.length === data.length && size) {
      const firstMatchIndex = matchCoordinates.findIndex((item) => item.comfortScore >= 60)
      const lastMatchIndex = matchCoordinates.length - 1 - [...matchCoordinates].reverse().findIndex((item) => item.comfortScore >= 60)
      const offset = 15
      const leftOffset = matchCoordinates[firstMatchIndex].width / 2 + offset
      const rightOffset = matchCoordinates[lastMatchIndex].width / 2 + offset
      if (firstMatchIndex >= 0) {
        let xStart = 0
        if (firstMatchIndex === 0) {
          // If there's no item before the first match, use a fixed 24px delta
          xStart = matchCoordinates[firstMatchIndex].x - leftOffset
        } else {
          // If there is an item before the first match, use the halfway point between the current and the previous item
          xStart = matchCoordinates[firstMatchIndex].x - leftOffset
        }
        let width = 0
        if (lastMatchIndex === matchCoordinates.length - 1) {
          // If there's no next item after the last match, use a fixed 24px delta
          width = matchCoordinates[lastMatchIndex].x + rightOffset - xStart
        } else {
          // If there is an item after the last match, extend width to halfway between the current and the next item
          width = matchCoordinates[lastMatchIndex].x + rightOffset - xStart
        }
        const height = (forReport ? theme.reports?.comfortMatchPage?.chartHeight ?? 280 : size.height) - chartPadding.bottom - comfortZoneHeightOffset
        return { width, height, x: xStart }
      }
    } else {
      return undefined
    }
  }, [chartPadding.bottom, comfortZoneHeightOffset, data.length, forReport, matchCoordinates, size, theme.reports?.comfortMatchPage?.chartHeight])

  const comfortZoneTooltipWidth = 300

  return (
    <div className="w-full h-full flex flex-col overflow-hidden absolute">
      <div className="grow overflow-hidden" ref={containerRef}>
        {size && (
          <VictoryChart
            containerComponent={<VictoryContainer responsive={false} />}
            height={forReport ? theme.reports?.comfortMatchPage?.chartHeight ?? 280 : size.height}
            padding={chartPadding}
            width={forReport ? theme.reports?.comfortMatchPage?.chartWidth ?? size.width : size.width}
            domain={{ x: [xAxisLabels[0], xAxisLabels[xAxisLabels.length - 1]] }}
          >
            {comfortZoneArea && (
              <g>
                <rect
                  style={{
                    width: `${comfortZoneArea.width}px`,
                    height: `${comfortZoneArea.height}px`
                  }}
                  width={comfortZoneArea.width}
                  height={comfortZoneArea.height}
                  className="risk-comfort-match-highlight fill-interactive-100"
                  x={comfortZoneArea.x}
                  y={comfortZoneHeightOffset}
                />
                <foreignObject
                  style={{
                    width: `${comfortZoneArea.width}px`,
                    height: `${comfortZoneArea.height}px`
                  }}
                  width={comfortZoneArea.width}
                  height={comfortZoneArea.height}
                  className="risk-comfort-match-highlight-text mt-4"
                  x={comfortZoneArea.x}
                  y={comfortZoneHeightOffset}
                >
                  <div
                    className={clsx(
                      "risk-comfort-match-label text-center flex justify-center items-center gap-x-1 text-highlight-700 text-xs font-semibold pt-4",
                      { "flex-col gap-y-1": comfortZoneArea.width < 50 }
                    )}
                  >
                    <p>Comfort zone</p>
                    {!forReport && (
                      <div
                        className="w-5 h-auto"
                        onMouseOver={() => {
                          setComfortZoneTooltipX(comfortZoneArea.x - (comfortZoneTooltipWidth - comfortZoneArea.width) / 2)
                          setIsComfortZoneTooltipVisible(true)
                        }}
                        onMouseOut={() => setIsComfortZoneTooltipVisible(false)}
                      >
                        <img className="cursor-pointer" src={infoIcon} aria-hidden />
                      </div>
                    )}
                  </div>
                </foreignObject>
              </g>
            )}
            <VictoryAxis
              axisLabelComponent={<VictoryLabel dy={forReport ? 20 : 30} />}
              domain={{
                x: [
                  xAxisLabels[0],
                  maxXAxisValue === adjustedMaxXAxisValue ? adjustedMaxXAxisValue + Math.ceil(maxXAxisValue / data.length) : adjustedMaxXAxisValue
                ]
              }}
              label="Expected annual volatility"
              style={{
                axis: { stroke: "#E4E2DE", strokeDasharray: "2 2", strokeWidth: 1 },
                axisLabel: {
                  fill: "#1A1C2F",
                  fontSize: 14,
                  fontFamily: "Mulish",
                  fontWeight: 600
                },
                tickLabels: {
                  fill: "#1A1C2F",
                  fontSize: 12,
                  fontFamily: "Mulish"
                }
              }}
              tickValues={xAxisLabels}
              tickFormat={(xAxislabel: any) => `${xAxislabel}%`}
            />
            <VictoryAxis
              axisLabelComponent={<VictoryLabel dy={-40} />}
              crossAxis={false}
              dependentAxis={true}
              domain={[0, forReport ? maxYAxisValue + 2 : maxYAxisValue]}
              label="Expected annual return"
              style={{
                axis: { stroke: "transparent" },
                grid: {
                  fill: "#E4E2DE",
                  stroke: "#E4E2DE",
                  pointerEvents: "painted",
                  strokeDasharray: "2 2",
                  strokeWidth: 1
                },
                axisLabel: {
                  fill: "#1A1C2F",
                  fontSize: 14,
                  fontFamily: "Mulish",
                  fontWeight: 600
                },
                tickLabels: {
                  fill: "#1A1C2F",
                  fontSize: 12,
                  fontFamily: "Mulish"
                }
              }}
              tickCount={5}
              tickLabelComponent={<VictoryLabel dx={-5} text={({ datum }) => `${datum}%`} />}
            />
            <VictoryLine
              data={data}
              interpolation="natural"
              labelComponent={<VictoryLabel text="" />}
              style={{
                data: {
                  opacity: 0.5,
                  stroke: line,
                  strokeDasharray: "2 2",
                  strokeWidth: 2
                }
              }}
            />

            <VictoryScatter
              data={data}
              dataComponent={
                <ChartPoint
                  setCoordinates={({ x, y, width, comfortScore }) => {
                    if (!isResizing && !matchCoordinates.find((c) => c.x == x && c.y === y)) {
                      setMatchCoordinates([{ x, y, width, comfortScore }, ...matchCoordinates].slice(0, data.length))
                    }
                  }}
                  onComfortMatchMouseOver={onComfortMatchMouseOver}
                  onComfortMatchMouseOut={() => setIsComfortMatchTooltipVisible(false)}
                  onCurrentInvestmentMouseOver={onCurrentInvestmentMouseOver}
                  onCurrentInvestmentMouseOut={() => setIsCurrentInvestmentTooltipVisible(false)}
                  client={client}
                  household={household}
                  forReport={forReport}
                  isPostMeeting={isPostMeeting}
                />
              }
              labelComponent={<VictoryTooltip text="" />}
            />
            {isComfortZoneTooltipVisible && comfortZoneTooltipX && (
              <g style={{ pointerEvents: "none" }}>
                <foreignObject height={50} x={comfortZoneTooltipX} y={64} width={comfortZoneTooltipWidth}>
                  <div className="h-full bg-white border border-interactive-200 z-10 shadow px-3 pt-2 pb-[10px]">
                    <p className="text-sm">The comfort zone highlights where you have a Risk Comfort score of 60% or above.</p>
                  </div>
                </foreignObject>
              </g>
            )}
            {isComfortMatchTooltipVisible && comfortMatchXY && (
              <ChartIconTooltip x={comfortMatchXY.x} y={comfortMatchXY.y} chartWidth={size.width} tooltipHeight={client || clientName ? 58 : 66}>
                <p className="text-sm leading-3">
                  {client && "Your highest Risk Comfort investment aligns most closely with your preferred level of risk and return."}
                  {household &&
                    `${clientName ? `${clientName}'s` : "Your"} highest Risk Comfort investment aligns most closely with ${
                      clientName ? `${clientName}'s preferred level of` : "your combined preferences for"
                    } risk and return.`}
                </p>
              </ChartIconTooltip>
            )}
            {isCurrentInvestmentTooltipVisible && currentInvestmentXY && (
              <ChartIconTooltip x={currentInvestmentXY.x} y={currentInvestmentXY.y} chartWidth={size.width}>
                <p className="text-sm leading-3">
                  Your current investment was selected with the aim of achieving your financial goals, without taking too much risk.
                </p>
              </ChartIconTooltip>
            )}
          </VictoryChart>
        )}
      </div>
    </div>
  )
}

export default RMJPortfolioComfortChart
