import { useTheme } from "../../contexts/ThemeContext"

const AlertIcon = ({ sizeProp, alertType, className }: { sizeProp?: "sm" | "md"; alertType?: "error" | "warning", className?: string }) => {
  const size = sizeProp && sizeProp === "sm" ? 13 : 20
  const theme = useTheme()
  return (
    <>
      {theme?.icons?.alertIcon ? (
        <img className={className} src={theme.icons.alertIcon} alt="" aria-hidden />
      ) : (
        <svg className={className} width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden>
          <path
            d="M6.49992 8.1276C6.79892 8.1276 7.04159 8.37027 7.04159 8.66927C7.04159 8.96827 6.79892 9.21094 6.49992 9.21094C6.20092 9.21094 5.95825 8.96827 5.95825 8.66927C5.95825 8.37027 6.20092 8.1276 6.49992 8.1276ZM6.49992 3.79427C6.79892 3.79427 7.04159 4.03694 7.04159 4.33594V7.04427C7.04159 7.34327 6.79892 7.58594 6.49992 7.58594C6.20092 7.58594 5.95825 7.34327 5.95825 7.04427V4.33594C5.95825 4.03694 6.20092 3.79427 6.49992 3.79427ZM6.49992 10.8359C4.11063 10.8359 2.16659 8.8919 2.16659 6.5026C2.16659 4.11331 4.11063 2.16927 6.49992 2.16927C8.88921 2.16927 10.8333 4.11331 10.8333 6.5026C10.8333 8.8919 8.88921 10.8359 6.49992 10.8359ZM6.49992 1.08594C3.50829 1.08594 1.08325 3.51098 1.08325 6.5026C1.08325 9.49423 3.50829 11.9193 6.49992 11.9193C9.49154 11.9193 11.9166 9.49423 11.9166 6.5026C11.9166 3.51098 9.49154 1.08594 6.49992 1.08594Z"
            fill={alertType === "warning" ? "#D49358" : "#C74B30"}
          />
        </svg>
      )}
    </>
  )
}

export default AlertIcon
