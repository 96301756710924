import { AxiosError } from "axios"
import { add, format, intervalToDuration } from "date-fns"
import { AnimatePresence } from "framer-motion"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { ClientUpdateRequest } from "../../../../api/clients"
import edit from "../../../../assets/icons/edit.svg"
import Loading from "../../../../components/ClientProfile/Loading/Loading"
import DatePicker from "../../../../components/DatePicker/DatePicker"
import ErrorMessage from "../../../../components/Error/ErrorMessage"
import Modal from "../../../../components/Modal/Modal"
import NumberInput from "../../../../components/NumberInput/NumberInput"
import { FEATURE_ALREADY_RETIRED } from "../../../../config/features"
import { ClientHouseholdCacheContext } from "../../../../contexts/ClientHouseholdCacheContext"
import { DATE_FORMAT, getAge } from "../../../../lib/date"
import { tt } from "../../../../lib/translations"
import { Client, GoalDetail } from "../../../../models/Client"
import { Household } from "../../../../models/Household"
import ToggleButton from "../../goalExplorer/components/ToggleButton/ToggleButton"
import { validateTargetDate } from "../../goalExplorer/wealthAccumulation/WealthAccumulationUtils"

const InvestmentTimeframe = ({
  client,
  household,
  goal,
  onUpdateDone
}: {
  client?: Client
  household?: Household
  goal: GoalDetail
  onUpdateDone: (goalDetail?: GoalDetail) => void
}) => {
  const [isInvestmentTimeframeModalOpen, setIsInvestmentTimeframeModalOpen] = useState(false)
  const [retirementClient, setRetirementClient] = useState<Client>()
  const clientOrHousehold = client ?? household
  const { updateClient, updateHousehold } = useContext(ClientHouseholdCacheContext)
  const [retirementAge, setRetirementAge] = useState<{ years: number | undefined; months: number | undefined }>({ years: undefined, months: undefined })
  const [retirementDate, setRetirementDate] = useState<Date | null>()
  const [alreadyRetired, setAlreadyRetired] = useState<boolean | undefined>(false)
  const [shouldValidate, setShouldValidate] = useState(false)
  const [targetDate, setTargetDate] = useState<Date | null>()
  const [targetDateRaw, setTargetDateRaw] = useState<string | null>()
  const [errors, setErrors] = useState<{ targetDate?: string; retirementAgeYears?: string; retirementAgeMonths?: string }>()

  useEffect(() => {
    setAlreadyRetired(retirementClient?.alreadyRetired)
  }, [retirementClient?.alreadyRetired])

  useEffect(() => {
    const duration =
      retirementClient?.dob && retirementClient.retirementDate
        ? intervalToDuration({ start: new Date(retirementClient.dob), end: new Date(retirementClient.retirementDate) })
        : undefined
    if (duration) {
      setRetirementAge({ years: duration ? duration.years : retirementClient?.retirementAge, months: duration ? duration.months : 0 })
    }
  }, [retirementClient?.dob, retirementClient?.retirementAge, retirementClient?.retirementDate])

  const title =
    goal.type === "retirementIncome" && clientOrHousehold?.alreadyRetired
      ? "Retirement"
      : goal.type === "retirementIncome"
      ? "Retirement age"
      : tt({ id: `goal-projector-form-${goal.type}-target-date-label` })

  const targetDateLabel = useMemo(() => {
    const diffFromNow = goal.targetDate?.value ? intervalToDuration({ start: new Date(), end: new Date(goal.targetDate?.value) }) : undefined
    return diffFromNow ? `${`${format(new Date(goal.targetDate!.value!), DATE_FORMAT)}`}${` (in ${diffFromNow.years}yrs ${diffFromNow.months}mos)`}` : ""
  }, [goal.targetDate])

  const getRetirementDateLabel = useCallback((client: Client) => {
    if (client.alreadyRetired) {
      return "Already retired"
    }
    const retirementAge = client.retirementDate
      ? intervalToDuration({ start: new Date(client.dob), end: new Date(client.retirementDate) })
      : { years: client.retirementAge, months: 0 }
    const diffFromNow = client.retirementDate ? intervalToDuration({ start: new Date(), end: new Date(client.retirementDate) }) : undefined
    const suffix = diffFromNow ? ` (in ${diffFromNow.years}yrs ${diffFromNow.months}mos)` : ""
    return `${`${retirementAge.years}yrs ${retirementAge.months}mos`}${suffix}`
  }, [])

  const { mutate: update, status } = useMutation<Client | Household | null, AxiosError<{ message?: string }>>({
    mutationFn: () => {
      const updateRequest: ClientUpdateRequest = {}
      if (goal.type === "retirementIncome" && alreadyRetired) {
        updateRequest.alreadyRetired = true
        updateRequest.retirementDate = null
        return updateClient(retirementClient!._id, updateRequest)
      } else if (goal.type === "retirementIncome" && !alreadyRetired && retirementDate) {
        updateRequest.alreadyRetired = false
        updateRequest.retirementDate = format(retirementDate, "yyyy-MM-dd")
        return updateClient(retirementClient!._id, updateRequest)
      } else {
        const updatedGoals = clientOrHousehold?.goals?.goalDetails?.map((goalDetail) => {
          if (goalDetail.type === goal?.type && goalDetail.id === goal.id) {
            return {
              ...goalDetail,
              targetDate: {
                value: format(targetDate!, "yyyy-MM-dd")
              }
            }
          } else {
            return goalDetail
          }
        })
        updateRequest.goals = { goalDetails: updatedGoals }
        return household ? updateHousehold(household._id, [], updateRequest) : updateClient(client!._id, updateRequest)
      }
    },
    onSuccess: (updated: Client | Household | null) => {
      onUpdateDone(updated?.goals?.goalDetails?.find((g) => g.type === goal.type && g.id === goal.id))
      setIsInvestmentTimeframeModalOpen(false)
    }
  })

  const validate = useCallback(() => {
    const errors: { targetDate?: string; retirementAgeYears?: string; retirementAgeMonths?: string } = {}
    if (goal.type !== "retirementIncome") {
      errors.targetDate = validateTargetDate(targetDate)
    } else if (goal.type === "retirementIncome" && !alreadyRetired && retirementClient?.dob) {
      const currentAge = getAge(retirementClient.dob)
      if (currentAge) {
        if (!retirementAge.years || retirementAge.years < 30 || retirementAge.years > 90) {
          errors.retirementAgeYears = "Age must be between 30-90 yrs"
        }
        if (retirementAge.months === undefined || retirementAge.months > 11) {
          errors.retirementAgeMonths = "Please add a month between 0-11"
        }
      }
    }
    setErrors(errors)
    return { isValid: Object.values(errors).filter((v) => !!v).length === 0 }
  }, [alreadyRetired, goal.type, retirementAge.months, retirementAge.years, targetDate, retirementClient?.dob])

  const onUpdate = () => {
    setShouldValidate(true)
    const { isValid } = validate()
    if (isValid) {
      update()
    }
  }

  useEffect(() => {
    if (shouldValidate) {
      validate()
    }
  }, [shouldValidate, validate])

  useEffect(() => {
    setTargetDate(goal.targetDate?.value ? new Date(goal.targetDate.value) : null)
  }, [goal.targetDate?.value])

  const ClientInvestmentTimeframeRenderer = ({ client }: { client?: Client }) => {
    return (
      <div className="flex gap-x-2 items-center w-full">
        <span>{title}</span>
        <div className="flex items-center gap-x-1">
          <span className="font-bold">{goal.type === "retirementIncome" && client ? getRetirementDateLabel(client) : targetDateLabel}</span>
          <button
            className="btn btn-text text-sec font-bold w-fit flex items-center justify-center align-middle p-1 text-main-500"
            aria-label="Edit investment timeframe"
            onClick={() => {
              setRetirementClient(goal.type === "retirementIncome" ? client : undefined)
              setIsInvestmentTimeframeModalOpen(true)
            }}
          >
            <img src={edit} alt="" aria-hidden />
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex">
      <div className="flex flex-col items-center gap-y-1 text-sec text-main-500">
        {goal.type === "retirementIncome" ? (
          client ? (
            <ClientInvestmentTimeframeRenderer client={client} />
          ) : (
            household?.members.map(({ client }) => client && <ClientInvestmentTimeframeRenderer client={client} key={client._id} />)
          )
        ) : (
          <ClientInvestmentTimeframeRenderer />
        )}
      </div>
      <AnimatePresence>
        {isInvestmentTimeframeModalOpen && (
          <Modal handleClose={() => setIsInvestmentTimeframeModalOpen(false)} className="max-w-xl w-full">
            <div className="w-full">
              <h2 className="text-h2 font-semibold pb-6">Edit investment timeframe</h2>
              {goal.type === "retirementIncome" ? (
                <div className="flex flex-col">
                  <label className="text-sec text-main-600 font-semibold mb-1" htmlFor="retirementAge">
                    {retirementClient?.firstName}'s retirement age
                  </label>
                  <div className="flex flex-row gap-x-4 mb-6">
                    <div>
                      <NumberInput
                        className="!flex-shrink"
                        disableAutoComplete
                        isDisabled={alreadyRetired}
                        name="retirementAge"
                        value={retirementAge.years}
                        suffix={<span className="text-sec text-main-400">years</span>}
                        aria-label="Retirement age in years"
                        onChange={(value) => {
                          setRetirementAge((prev) => {
                            return {
                              ...prev,
                              years: value
                            }
                          })
                          setRetirementDate(
                            retirementClient?.dob ? add(new Date(retirementClient.dob), { years: value ?? 0, months: retirementAge.months ?? 0 }) : null
                          )
                        }}
                      />
                      <div role="alert">
                        {shouldValidate && errors?.retirementAgeYears && <ErrorMessage id="retirement-age-years" message={errors.retirementAgeYears} />}
                      </div>
                    </div>
                    <div>
                      <NumberInput
                        className="!flex-shrink"
                        disableAutoComplete
                        isDisabled={alreadyRetired}
                        name="retirementAgeMonths"
                        value={retirementAge.months}
                        suffix={<span className="text-sec text-main-400">months</span>}
                        aria-label="Retirement age in months"
                        onChange={(value) => {
                          setRetirementAge((prev) => {
                            return {
                              ...prev,
                              months: value
                            }
                          })
                          setRetirementDate(
                            retirementClient?.dob ? add(new Date(retirementClient.dob), { years: retirementAge.years ?? 0, months: value ?? 0 }) : null
                          )
                        }}
                      />
                      <div role="alert">
                        {shouldValidate && errors?.retirementAgeMonths && <ErrorMessage id="retirement-age-months" message={errors.retirementAgeMonths} />}
                      </div>
                    </div>
                  </div>
                  {FEATURE_ALREADY_RETIRED && (
                    <ToggleButton
                      id="alreadyRetired"
                      toggleState={!!alreadyRetired}
                      onClick={() => {
                        setAlreadyRetired(!alreadyRetired)
                      }}
                      label={<span className="font-semibold">Already in retirement</span>}
                      className="flex-row-reverse !w-full justify-between"
                    />
                  )}
                </div>
              ) : (
                <DatePicker
                  id="target-date"
                  label={
                    <span className="font-semibold">
                      {goal ? tt({ id: `goal-projector-form-${goal?.type}-target-date-label` }) : "Expected withdrawal year"}
                    </span>
                  }
                  value={targetDate || null}
                  onChange={setTargetDate}
                  onChangeRaw={setTargetDateRaw}
                  yearsInAdvance={75}
                  error={shouldValidate && errors?.targetDate ? errors.targetDate : undefined}
                />
              )}
              {/* <div role="alert">{status === "error" && <ErrorMessage id="edit-investment-timeframe" message="Error updating the value" />}</div> */}
              <div className="flex justify-center gap-5 mt-6">
                <button type="button" onClick={() => setIsInvestmentTimeframeModalOpen(false)} className="btn btn-secondary btn-medium w-44">
                  Cancel
                </button>
                <button className="btn btn-primary btn-medium w-44" disabled={status === "loading"} onClick={() => onUpdate()}>
                  {status === "loading" ? <Loading type="dots" /> : "Update"}
                </button>
              </div>
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </div>
  )
}

export default InvestmentTimeframe
