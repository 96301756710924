import { t } from "@lingui/macro"
import React, { useMemo } from "react"
import { useTheme } from "../../../contexts/ThemeContext"
import { Client } from "../../../models/Client"
import checkmarkIcon from "./assets/checkmark.svg"
import Page from "./Page"
import { clientTopCause } from "../components/Profile/causeScores"

const InvestingChampionPage = ({ page, client }: { page: number; client: Client }) => {
  const theme = useTheme()
  const cause = useMemo(() => clientTopCause(client, theme), [client, theme])

  return (
    <Page page={page} title={t({ id: "print-report-page-header-title" })}>
      <div className="mx-9 h-full relative">
        <h3 className="text-[22px]">{cause?.report?.investmentChampionPersonaTitle}</h3>
        <div className="mt-8 text-sec font-normal leading-5">
          <p>{cause?.report?.investmentChampionTitle}</p>
          <div className="flex justify-between">
            <ul className="list-none mt-3">
              {cause?.themeItems?.map((item) => (
                <React.Fragment key={item.title}>
                  <li className="flex mr-3 mb-1">
                    <img className="mr-2 w-4" src={checkmarkIcon} alt="" aria-hidden />
                    <p>{item.title}</p>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-10">
          <h2 className="font-semibold text-sec">Your values and the UN Sustainable Development Goals</h2>
          <div className="font-normal mt-2 text-sec leading-5">
            <p>
              The Sustainable Development Goals (SDGs) were adopted by the United Nations in 2015 as a universal call to end poverty, protect the planet and
              ensure that all people enjoy peace and prosperity. Asset managers use the SDGs to help them direct sustainable investments towards the most
              pressing environmental and social challenges.
            </p>
            <p className="mt-6">The theme of promoting sustainability aligns with the following SDGs:</p>
            <div className="mt-3">
              {cause?.learn?.devGoals?.items.map((sdg, i) => (
                <div className="flex mb-2" key={i}>
                  <img className="mr-1 w-5" src={sdg.icon} alt="" aria-hidden />
                  <p>{sdg.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 bg-[#BFDB7D]">
          <img src={theme.reports?.investingChampion?.insightsImage} alt="" aria-hidden />
          {theme.reports?.investingChampion?.insightsLink && <div className="relative py-5">
            <a
              className="ml-3 leading-5 font-medium flex !text-black !no-underline"
              href={theme.reports?.investingChampion?.insightsLink}
            >
              Read our latest insights on sustainable investing
              <img className="w-5 h-5" src={theme?.icons?.chevronRight} alt="" aria-hidden />
            </a>
          </div>}
        </div>
      </div>
    </Page>
  )
}

export default InvestingChampionPage
