import { clsx } from "clsx"
import { AnimatePresence } from "framer-motion"
import { useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"
import { useLocation, useNavigate, useParams } from "react-router"
import { computeGoalMappingScores } from "../../../api/rm/goals"
import edit from "../../../assets/icons/edit.svg"
import alert from "../../../assets/icons/info-alert.svg"
import Loading from "../../../components/ClientProfile/Loading/Loading"
import ErrorMessage from "../../../components/Error/ErrorMessage"
import RadioButton from "../../../components/RadioButton/RadioButton"
import { useTheme } from "../../../contexts/ThemeContext"
import useClient from "../../../hooks/useClient"
import useHousehold from "../../../hooks/useHousehold"
import { latest } from "../../../lib/clients"
import { tt } from "../../../lib/translations"
import { GoalDetail } from "../../../models/Client"
import CardTitle, { getGoalTitle } from "../../advisor/Results/Goals/components/CardTitle"
import { GoalPrioritiesOrder } from "../../advisor/Results/Goals/GoalsTab"
import { comfortZoneText } from "../../advisor/Results/Risk/RiskComfortZone/RiskComfortZone"
import { allAssetClasses } from "../../clients/reports/ComfortMatchPage"
import riskComfortIcon from "../assets/images/riskComfort.svg"
import ChartDisplayToggle from "../goalExplorer/components/ChartDisplayToggle/ChartDisplayToggle"
import chartIcon from "./assets/chart.svg"
import listIcon from "./assets/list.svg"
import pieChartFillIcon from "./assets/pie-chart-fill.svg"
import GoalInvestmentTimeframe from "./InvestmentTimeframe/GoalInvestmentTimeframe"
import InvestmentTimeframeModal, { defaultTimeframe, timeframeOptions } from "./InvestmentTimeframeModal/InvestmentTimeframeModal"
import RMJPortfolioComfortChart from "./RMJPortfolioComfortChart/RMJPortfolioComfortChart"
import RMJPortfolioComfortMatchListView from "./RMJPortfolioComfortMatchListView"
import { FEATURE_TIMEFRAME_MAPPING_IN_IM } from "../../../config/features"

export const AVATAR_ICON_FILL_CLASSES = ["fill-avatar-0-500", "fill-avatar-1-500"]
export const AVATAR_ICON_BG_CLASSES = ["bg-avatar-0-500", "bg-avatar-1-500"]

const RMJPortfolioComfort = ({ translationContext = "client" }: { translationContext?: "advisor" | "client" }) => {
  const { clientId, householdId } = useParams<{ clientId: string; householdId: string }>()
  const { client } = useClient(clientId)
  const { household } = useHousehold(householdId)
  const clientOrHousehold = client ?? household
  const theme = useTheme()
  const [isInvestmentTimeframeModalOpen, setIsInvestmentTimeframeModalOpen] = useState(false)
  const [displayType, setDisplayType] = useState<"chart" | "list">("chart")
  const [selectedGoal, setSelectedGoal] = useState<GoalDetail>()
  const navigate = useNavigate()

  const completedMembers = household?.members?.filter((member) => latest(member.client, "risk")?.played)

  const game = completedMembers?.length === 1 ? latest(completedMembers[0].client, "risk") : latest(clientOrHousehold!, "risk")
  const [portfolioMappings, setPortfolioMappings] = useState(game?.portfolioMappings)

  useEffect(() => {
    setPortfolioMappings(game?.portfolioMappings)
  }, [game?.portfolioMappings])

  const comfortMatches = useMemo(() => {
    return [...(portfolioMappings || [])].sort((a, b) => {
      const d = b.riskComfort! - a.riskComfort!
      return d ? d : (a.portfolio!.sd || 0) - (b.portfolio!.sd || 0)
    })
  }, [portfolioMappings])

  const comfortMatch = comfortMatches[0]

  const assetClasses = clientOrHousehold ? allAssetClasses(game?.assetClasses ?? []) : {}

  const comfortZoneAlignText = game ? comfortZoneText(game!) : null

  const location = useLocation()
  const currentState = location.state || {}
  const { shouldValidate } = currentState || {}

  const sortedGoalDetails = useMemo(
    () =>
      [...(clientOrHousehold?.goals?.goalDetails ?? [])].sort((a, b) => {
        const priorityA = a.priority ? GoalPrioritiesOrder.indexOf(a.priority) : Infinity
        const priorityB = b.priority ? GoalPrioritiesOrder.indexOf(b.priority) : Infinity
        return priorityA - priorityB
      }),
    [clientOrHousehold?.goals?.goalDetails]
  )

  const goalsWithTimeframe = useMemo(() => {
    return sortedGoalDetails.filter((g) =>
      g.type === "retirementIncome"
        ? household
          ? household.members.some((m) => m.client.retirementDate || m.client.alreadyRetired)
          : client?.retirementDate || client?.alreadyRetired
        : !!g.targetDate?.value
    )
  }, [client?.alreadyRetired, client?.retirementDate, household, sortedGoalDetails])

  useEffect(() => {
    if (FEATURE_TIMEFRAME_MAPPING_IN_IM && !selectedGoal) {
      setSelectedGoal(goalsWithTimeframe[0])
    }
  }, [selectedGoal, goalsWithTimeframe])

  const showGoalTabs = useMemo(() => FEATURE_TIMEFRAME_MAPPING_IN_IM && goalsWithTimeframe.length > 1, [goalsWithTimeframe.length])

  const selectedGoalTitle = useMemo(() => {
    if (selectedGoal) {
      const title = getGoalTitle({ client, household, goalDetails: sortedGoalDetails, goal: selectedGoal })
      return title || tt({ id: `profile-page-status-card-goals-goal-${selectedGoal.type}-title` })
    }
    return null
  }, [client, household, selectedGoal, sortedGoalDetails])

  const { mutate: calcComfortScores, status: calcComfortScoresStatus } = useMutation({
    mutationFn: () => computeGoalMappingScores({ goal: selectedGoal, clientId: client?._id, householdId: household?._id }),
    onSuccess: (res) => {
      setPortfolioMappings(res)
    }
  })

  useEffect(() => {
    if (FEATURE_TIMEFRAME_MAPPING_IN_IM && (client || household || selectedGoal)) {
      calcComfortScores()
    }
  }, [client, household, selectedGoal, calcComfortScores])

  return (
    <div className={clsx("w-full h-full flex flex-col items-start", { "pb-[35px]": showGoalTabs, "py-7": !showGoalTabs })}>
      {!portfolioMappings ? (
        <Loading />
      ) : (
        <>
          {showGoalTabs && (
            <div className="flex flex-row w-full mb-10 bg-white border-b border-b-surface-300 shadow-100">
              {goalsWithTimeframe.map((goal, i) => {
                const isSelected = selectedGoal?.type === goal.type && selectedGoal.id === goal.id
                return (
                  <label
                    key={i}
                    htmlFor={goal.type + goal.id}
                    className={clsx(
                      "relative flex flex-row gap-x-1 items-center text-left text-sm py-4 pl-3 pr-2.5 cursor-pointer text-interactive-500 whitespace-nowrap",
                      {
                        "font-bold border-b-2 border-interactive-500": isSelected,
                        "font-semibold": !isSelected
                      }
                    )}
                  >
                    <RadioButton
                      name="selected-goal"
                      id={goal.type + goal.id}
                      className="hidden"
                      checked={isSelected}
                      onChange={() => {
                        setSelectedGoal(goal)
                        navigate("", { state: { ...currentState, goal: goal.type, id: goal.id ?? "" } })
                      }}
                      aria-checked={isSelected}
                    />
                    <span id={goal.type + goal.id + "-label"}>
                      <CardTitle client={client} household={household} goalDetails={clientOrHousehold?.goals?.goalDetails ?? []} goal={goal} />
                    </span>
                    {shouldValidate && (goal.selectedPortfolio === undefined || !goal.targetAmount?.value) && (
                      <img src={alert} className="h-4 w-4" alt="No selected investment" />
                    )}
                  </label>
                )
              })}
            </div>
          )}
          <div className="flex flex-col w-full h-full px-10">
            <div className="w-full flex justify-between items-center mb-6">
              <div>
                {!showGoalTabs && <h1 className="text-sec text-main-600 mb-1 uppercase">Risk Comfort</h1>}
                <h2 className={clsx("text-h3 font-semibold text-main-600", { "": showGoalTabs })}>
                  {selectedGoal
                    ? `Your Risk Comfort for your ${selectedGoalTitle} goal`
                    : client || (household && completedMembers?.length === 1)
                    ? tt({
                        id: `portfolio-comfort-title-individual-${translationContext}`,
                        message: "Your Risk Comfort with each of our investment options",
                        values: { firstName: household ? completedMembers![0].client.firstName : client?.firstName }
                      })
                    : tt({
                        id: `portfolio-comfort-title-household-${translationContext}`,
                        message: "Your Risk Comfort with each of our investment options",
                        values: { firstName: household?.members[0].client.firstName, secondName: household?.members[1].client.firstName }
                      })}
                </h2>
              </div>
              <ChartDisplayToggle
                toggle1={<img src={chartIcon} alt="Chart view" />}
                toggle2={<img src={listIcon} alt="List view" />}
                onClick={(value) => setDisplayType(value)}
                valueDisplayType={displayType!}
              />
            </div>
            {theme.charts?.comfortMatch?.showDescription && comfortZoneAlignText && (
              <p className="risk-results-portfolio-comfort-description">
                The comfort zone reflects where {client?.firstName}’s currently most comfortable with investment risk. Based on their decisions,{" "}
                {client?.firstName}’s {comfortZoneAlignText}
              </p>
            )}
            {FEATURE_TIMEFRAME_MAPPING_IN_IM &&
              (selectedGoal ? (
                <GoalInvestmentTimeframe client={client} household={household} goal={selectedGoal ?? sortedGoalDetails[0]} onUpdateDone={setSelectedGoal} />
              ) : (
                <div className="flex items-center gap-x-2 text-sec text-main-500">
                  <span>Investment timeframe</span>
                  <div className="flex items-center gap-x-1">
                    <span className="font-bold">
                      {timeframeOptions.find((t) => t.value === (clientOrHousehold?.investmentTimeframe ?? defaultTimeframe))?.label}
                    </span>
                    <button
                      className="btn btn-text text-sec font-bold w-fit flex items-center justify-center align-middle p-1 text-main-500"
                      onClick={() => setIsInvestmentTimeframeModalOpen(true)}
                      aria-label="Edit investment timeframe"
                    >
                      <img src={edit} alt="" aria-hidden />
                    </button>
                  </div>
                </div>
              ))}
            {calcComfortScoresStatus === "loading" && <Loading />}
            {calcComfortScoresStatus === "error" && <ErrorMessage id="calc-error" message="Error calculating scores, please try again" />}
            {(!FEATURE_TIMEFRAME_MAPPING_IN_IM || calcComfortScoresStatus === "success") &&
              (displayType === "chart" ? (
                <>
                  <div className="relative min-h-[360px] w-full grow mb-6">
                    <RMJPortfolioComfortChart
                      allAssetClasses={assetClasses}
                      client={client}
                      household={household}
                      portfolioMappings={portfolioMappings}
                      comfortMatch={comfortMatch}
                    />
                  </div>
                  {theme.charts?.comfortMatch?.showLegend && (
                    <div className="w-full flex justify-center text-sm">
                      <div className="w-12" />
                      <div className="h-8 flex flex-grow justify-center items-center gap-5 shrink-0">
                        <p className="flex gap-1 items-center">
                          <img alt="" className="w-3 shrink-0" src={riskComfortIcon} aria-hidden />
                          Highest Risk Comfort
                        </p>
                        {clientOrHousehold?.currentPortfolio && (
                          <p className="flex gap-1 items-center">
                            <img alt="" className="w-2.5 shrink-0" src={pieChartFillIcon} aria-hidden />
                            Current investment
                          </p>
                        )}
                        {household?.members
                          ?.filter((member) => member.client.games?.find((game) => game.gameType === "risk")?.played)
                          .map(({ client }, i) => (
                            <div className="flex gap-1 items-center" key={client._id}>
                              <p className={clsx("w-2 h-2 rounded-full", AVATAR_ICON_BG_CLASSES[i])} />
                              {client.firstName}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="w-full">
                  <RMJPortfolioComfortMatchListView
                    forReport={false}
                    allAssetClasses={assetClasses}
                    client={client}
                    household={household}
                    comfortMatch={comfortMatch}
                    portfolioMappings={portfolioMappings}
                  />
                </div>
              ))}
          </div>
        </>
      )}
      <AnimatePresence>
        {isInvestmentTimeframeModalOpen && (
          <InvestmentTimeframeModal client={client} household={household} onClose={() => setIsInvestmentTimeframeModalOpen(false)} />
        )}
      </AnimatePresence>
    </div>
  )
}

export default RMJPortfolioComfort
